
/*!
 Theme Name:   Clarke Timber Theme
 Theme URI:    http://clarke.edu
 Description:  Timber-based theme for Clarke University
 Author:       Jesse Janowiak
 Author URI:   http://insidenewcity.com
 Template:     timber
 Version:      1.0.0
 License:      NONE
 Tags:
 Text Domain:  clarke-timber
*/
