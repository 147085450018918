$icon-size: 2em;

$icon-block-radius: 50%;
$icon-block-light-bg: #fff;

// Third-party colors
$color-facebook: #3b5999;
$color-flickr-1: #3766b0;
$color-flickr-2: #ed1f83;
$color-flickr-bg: #eaeaea;
$color-github: #181617;
$color-instagram: #32669a;
$color-kickstarter: #7bc143;
$color-kickstarter-bg: #161e13;
$color-linkedin: #017bb6;
$color-pinterest: #ca2128;
$color-reddit: #f04a23;
$color-rss: #ed802d;
$color-tumblr: #45546b;
$color-twitter: #2aa8e0;
$color-vine: #00b386;
$color-wordpress: #0864a6;
$color-youtube: #cd332d;

@function check-lightness($color) {
	@if (lightness($color) > 20) {
		@return true;
	} @else {
		@return false;
	}
}

@mixin icon-color-scheme($icon-color, $icon-color-bg:$icon-block-light-bg) {

	.icons--color &,
	&.icons--color {
		color: $icon-color;
	}

	.icons--boxes &,
	&.icons--boxes {
		color: $icon-color-bg;

		border-radius: $icon-block-radius;

		svg {
			fill: $icon-color;
		}
	}

	.icons--one-color &,
	&.icons--one-color {
		color: currentColor;
	}

	.icons--one-color.icons--boxes &,
	&.icons--one-color.icons--boxes {
		color: $icon-block-light-bg;

		svg {
			fill: $anchor-color;
		}
	}


	.icons--dark-bg:not(.icons--boxes) &,
	&.icons--dark-bg:not(.icons--boxes) {
		color: $icon-block-light-bg;
	}

	.icons--dark-bg.icons--boxes &,
	&.icons--dark-bg.icons--boxes {
		@if (check-lightness($icon-color)) {
			color: $icon-color-bg;

			svg {
				fill: $icon-color;
			}
		} @else {
			color: $icon-color;

			svg {
				fill: $icon-block-light-bg;
			}
		}
	}

	.icons--dark-bg.icons--boxes.icons--one-color &,
	&.icons--dark-bg.icons--boxes.icons--one-color {
		color: transparent;

		svg {
			fill: $icon-block-light-bg;
		}
	}
}

.icons--dark-bg {
	color: $icon-block-light-bg;
}

.icon-wrapper {
//	border-radius: $icon-block-radius;
	background-color: transparent;

	width: $icon-size;
	height: $icon-size;

	overflow: hidden;

	svg {
		display: block;

		width: 100%;
		height: 100%;

		fill: none;
	}

	&.facebook {
		@include icon-color-scheme($color-facebook);

	}

	&.flickr {

		@include icon-color-scheme($color-flickr-2);

	}

	&.github {
		@include icon-color-scheme($color-github);

		}


	&.instagram {
		@include icon-color-scheme($color-instagram);
	}


	&.kickstarter {
		@include icon-color-scheme($color-kickstarter, $color-kickstarter-bg);

	}

	&.linkedin {
		@include icon-color-scheme($color-linkedin);
	}

	&.pinterest {
		@include icon-color-scheme($color-pinterest);

	}

	&.reddit {
		@include icon-color-scheme($color-reddit);

	}

	&.rss {
		@include icon-color-scheme($color-rss);

	}

	&.tumblr {
		@include icon-color-scheme($color-tumblr);
	}

	&.twitter {
		@include icon-color-scheme($color-instagram);
	}

	&.vine {
		@include icon-color-scheme($color-vine);
	}

	&.wordpress {
		@include icon-color-scheme($color-wordpress);
	}

	&.youtube {
		@include icon-color-scheme($color-youtube);
	}


}
