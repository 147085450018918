/* Links
/* _______________________________________________________________ */

a {
	// Set default color, text-decoration, and hover color in
	// _foundation-settings.scss using $anchor-… variables
	transition: $transition-time;
	outline: 0;

	p & {
		text-decoration: underline;
	}
}

main, footer {

	a, button {

		&.secondary {
			//		color: $color-secondary;
			@include anchor-colors($anchor-color, $color-primary);
		}

		&.link--arrow {
			color: $anchor-color-hover;

			//	@include underline(rgba($anchor-color-hover, 0));

			background-repeat: no-repeat;
			background-size: calc(100% - 1.475em) 2px;

			@include hover {
				color: $anchor-color;
				//		@include underline($anchor-color);
				background-repeat: no-repeat;
				background-size: calc(100% - 1.475em) 2px;
			}

			&.secondary {
				//		@include underline(rgba($anchor-color-hover, 0));
				background-repeat: no-repeat;
				background-size: calc(100% - 1.475em) 2px;

				@include hover {
					color: $color-primary;
					//			@include underline($color-primary);
					background-repeat: no-repeat;
					background-size: calc(100% - 1.475em) 2px;
				}
			}
		}

		&.link--icon {
			@include inline-icon;
			color: $anchor-color-hover;

			//	@include underline(rgba($anchor-color-hover, 0));

			background-repeat: no-repeat;
			background-position: right bottom;
			background-size: calc(100% - 1.25em) 2px;

			@include hover {
				color: $anchor-color;
				//		@include underline($anchor-color);
				background-repeat: no-repeat;
				background-position: right bottom;
				background-size: calc(100% - 1.25em) 2px;
			}

			&.secondary {
				//		@include underline(rgba($anchor-color-hover, 0));
				background-repeat: no-repeat;
				background-size: calc(100% - 1.25em) 2px;

				@include hover {
					color: $color-primary;
					//			@include underline($color-primary);
					background-repeat: no-repeat;
					background-size: calc(100% - 1.25em) 2px;
				}
			}

			.icon-wrapper {
				color: $color-shade-dark;
			}

		}
	}

	.anchor-dark-bg {
		@include anchor-colors($color-quinary, $color-accent-quinary);
	}

	.anchor-underlined {
		@include anchor-underline( 'all', 'border', $border-med, 0.75em, 'currentColor');
	}

	.link--block {
		text-transform: uppercase;

		.icon-wrapper {
			vertical-align: 0.05em;

			margin-left: 0.5em;
		}
	}

	.link--arrow,
	.link--arrow-down,
	.link--plus,
	.link--dropdown,
	.link--icon {
		@include inline-icon(right);
		position: relative;
	}

////////////////////////////////////
// For Newsroom internal page nav
	.link--arrow-down,
	.link--plus {
		color: $color-primary;
		&:hover, &:active {
			color: $color-teal;
		}
		span {
			width: .75em !important;
	    height: .75em !important;
	    margin-left: 0 !important;
			top: 0.15em !important;
		}
	}

// For links with no icons
	.link-list.inline.dropdown.menu li a.link {
		color: $color-primary;
		padding-right: rem-calc(28);
		&:hover, &:active {
			color: $color-teal;
		}
	}

	.link--dropdown {
		.icon-wrapper {
			margin-left: 0.5rem;
			transform: rotate3d(0, 0, 1, 90deg);
			transition: transform $transition-time ease;
		}

		&.active {
			.icon-wrapper {
				transform: rotate3d(0, 0, 1, -90deg);
			}
		}
	}

	.link-list {
		a {
			font-weight: bold;
		}

		&.inline {
			li {
				padding-right: 1.5em;
			}

			.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
				display: none;
			}

			&.dropdown {

				.is-dropdown-submenu-parent > a::after {
					display: none !important;
				}

				.is-dropdown-submenu {
					border-width: 0;
					box-shadow: 4px 4px 5px #bbb;
					//min-width: rem-calc(250);
					text-align: left;
				}
				.is-dropdown-submenu-item {
					line-height: 1.25;
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;
					a {
						color: $color-navy;
						font-size: rem-calc(16) !important;
						&:hover, &:active {
							color: $color-teal;
						}
					}
				}
			}
		}
	}
}

.hgroup--newsroom {
	.link-list {

		margin-left: auto;
		margin-right: auto;

		float: none;

		//@include breakpoint(medium) {
		@include breakpoint(820px) {
			float: right;
			margin-right: 0;
			bottom: 0;
	    top: 16px;
	    position: relative;
		}

		a {
			font-size: rem-calc(18);
			font-weight: normal;
		}

		&.inline {
			li {
				padding-right: 1em;
			}
		}

	}

}

.social-link {
	@include inline-icon;

	.icon-wrapper {
		width: 1.75em;
		height: 1.75em;
		margin-right: 0.5rem;
	}
}
