nav {
	&.bg-primary,
	.bg-primary & {
		a {
			@include anchor-colors($white);
		}
	}
}

//.dropdown {
//
//}

.menu--inline {
	@include breakpoint(menu-medium) {
		@include inline-list($padding: 0 0.25rem);
	}

	@include breakpoint(menu-large) {
		@include inline-list($padding: 0 0.75rem);
	}
}

.menu--main,
.menu--utility {
	@include strip-list;
	display: flex;
	flex-flow: column nowrap;
}

.menu--main {
	> li {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	//scss-lint:disable DeclarationOrder
	@include breakpoint(menu-medium) {
		@include inline-list($padding: 0 0.25rem, $flush: both);
		justify-content: space-between;
	}
}

.menu--utility {
	.site-header & {
		text-transform: uppercase;
	}
}

.footer__utility {
	ul {
		@include strip-list;
	}
}

.breadcrumbs {
	@include inline-list();
	@include font-size("small");

	justify-content: center;
	align-items: center;

	margin-bottom: $space-page-section;

	text-transform: uppercase;
	line-height: 1;

	> a {
		@include anchor-colors($color-text, $color-primary);
	}

	> .bc__spacer {
		color: $color-shade;
		font-size: 0.875em;
	}

	> a,
	> span {
		margin-right: 0.75rem;

		&:last-child {
			margin-right: 0;
		}
	}
}

.inline-arrows {
	@include font-size("small");

	left: 50%;
	right: auto;
	transform: translateX(-50%);
	padding: 0;

	font-weight: bold;

	color: $anchor-color;

	> * {
		top: 50%;
		//		transform: translateY(-50%);
	}

	ul {
		display: flex;
		position: relative;
		top: 0;
		bottom: 0;

		li {
			&.slick-active,
			&.pagination-active,
			&.current {
				button,
				a[role="button"]:not(.dots) {
					background-color: $color-secondary;
					color: $white;
					border-color: $color-secondary;
				}
			}
		}
	}
}

.pagination-numbers {
	position: relative;

	li {
		text-indent: 0;
		display: block;
		height: auto;
		width: auto;

		margin: 0 0.75em 0 0;
		padding: 0;

		cursor: pointer;

		&::before {
			display: none;
		}
	}

	a[role="button"] {
		display: block;
	}

	button,
	li button,
	a[role="button"] {
		@include font-size("medium");
		color: inherit;

		line-height: 1.5;
		padding: 0.5rem 1em;
		width: auto;
		height: auto;

		&::before {
			display: none;
		}

		&.pagination-disabled {
			color: rgba(desaturate($color-secondary, 30%), 0.25);
		}
	}

	.page-arrow {
		line-height: rem-calc(24);
		width: auto;
		font-size: 1em;
		color: inherit;

		padding: 0.5rem 0.75em;

		text-transform: uppercase;

		&::before {
			display: none;
		}
	}

	.page-arrow,
	li button,
	li a[role="button"] {
		background: transparent;
		border: solid 2px currentColor;
		height: auto;
		transition: color $transition-time ease-out, background-color $transition-time ease-out,
			border-color $transition-time ease-out;

		&.slick-disabled {
			color: rgba(desaturate($color-secondary, 30%), 0.25);
		}
	}
}

.list-filter {
	display: flex;
	flex-flow: row nowrap;

	font-weight: bold;

	&.centered {
		justify-content: center;
	}

	.filter-label {
		padding: 0.25em 1em 0.25em 0;

		&::after {
			content: ":";
		}
	}

	ul {
		@include strip-list;
		//		@include font-family($font-secondary);
		//		font-size: 1.375em;
		//		vertical-align: 0.25em;

		display: flex;
		flex-flow: row nowrap;
	}

	a {
		@include anchor-colors($color-primary, $anchor-color);

		display: block;

		margin-right: 0.25em;
		padding: 0.25em 0.625em;

		&.active {
			@include anchor-colors($white);

			background-color: $color-quinary;

			cursor: default;
		}
	}
}

.legend {
	display: flex;

	justify-content: center;
	align-items: center;

	padding: 1rem;

	background-color: $color-shade-lighter;

	.legend-item {
		display: flex;

		margin-right: 1.5rem;

		.legend-icon {
			margin-right: 0.5rem;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}
}
