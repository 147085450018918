.column--stretch {
	.feed-block {
		display: flex;
		flex-flow: column nowrap;

		justify-content: space-between;

		width: 100%;

		> * {
			flex: 0 0 auto;
		}

		> .event-feed {
			flex: 1 0 auto;
		}
	}
}

.block-row {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;

	figure {
//		margin: 0;
	}

	> * {
		margin-bottom: rem-calc(27);
	}

	> *:not(.photo-bg) {
//		display: block;
		padding-right: map-get($grid-column-gutter, small) / 2;
		padding-left: map-get($grid-column-gutter, small) / 2;
	}

	// breakpoint includes have to come at the end, despite the linter's
	// preferred declaration order
	//scss-lint:disable DeclarationOrder
	@include breakpoint(medium) {
		@for $i from 1 through 5 {
			&.block-row--medium-#{$i} {
				> *:not(.photo-bg) {
					width: percentage( (1 / $i) - 0.0005);
				}
			}
		}
	}

	&.content-center {
		align-items: center;
	}

	.content-wrapper > section.has-bg & {
		margin-bottom: -1.6875rem;
	}
}

.buttons--flex {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;

	.button {
		display: block;
		margin: 0 0.5rem 0.5rem;
	}
}

.gallery--mosaic {
	$gallery-gutter: 1rem;

	@include clearfix;


	@include breakpoint(medium) {
		margin-right: -$gallery-gutter / 2;
		margin-left: -$gallery-gutter / 2;
	}

	.gallery__content-wrapper {
		height: 100px;
	}


	figure {
		background-repeat: no-repeat;
		margin-top: 0;
		float: left;

		width: 100%;

		figcaption {
			display: none;
		}

		// breakpoint includes have to come at the end, despite the linter's
		// preferred declaration order
		//scss-lint:disable DeclarationOrder
		@include breakpoint(medium) {
			background-repeat: repeat-x;
			margin-bottom: $gallery-gutter;
			padding-right: $gallery-gutter / 2;
			padding-left: $gallery-gutter / 2;

		}


		img {
			display: block;
		}

	}

	&.gallery--3-per-page {
		figure,
		figure[style] {
			@include breakpoint(medium) {
				//scss-lint:disable ImportantRule
				width: percentage(1 / 3) !important;
			}
		}
	}

	// breakpoint includes have to come at the end, despite the linter's
	// preferred declaration order
	//scss-lint:disable DeclarationOrder
	@include breakpoint(medium) {
		.gallery-image--large {
			//scss-lint:disable ImportantRule
			width: 50% !important;
		}

		.gallery-image--medium {
			//scss-lint:disable ImportantRule
			width: 50% !important;
		}

		.gallery-image--small {
			//scss-lint:disable ImportantRule
			width: 25% !important;
		}
	}

}

@include breakpoint(small only) {
	ul.slick-dots {
		//scss-lint:disable ImportantRule
		display: none !important;
	}
}

.link-row {
	@include inline-list;
	justify-content: center;
	// flex-wrap: nowrap;

	.icon--circle {
		margin-right: auto;
		margin-bottom: rem-calc(21);
		margin-left: auto;

		width: 3.875rem;
		height: 3.875rem;
	}

	li {
		width: percentage(1 / 5);
		flex: 0 1 auto;
		text-align: center;
		padding: 0;

		@include hover {
			.icon--circle {
				background-color: $color-primary;
			}
		}

		+ li {
			@include dots-spacer-vertical;
		}

		a {
			display: block;

			padding: 0.5rem rem-calc(32);

			.icon--circle {
				background-color: $color-secondary;

				transition: background-color $transition-time ease;
			}

		}
	}

	.link-row__text {
		@include heading-epsilon;
	}

	li:nth-last-child(6):first-child,
	li:nth-last-child(6):first-child ~ li {
		width: percentage(1 / 6);

		@include breakpoint(870px down) {
			width: percentage(1 / 3);
			flex: 0 0 auto;
			margin-bottom: 2rem;

			&:nth-child(4)::before {
				content: '';
				width: 0;
			}
		}

		@include breakpoint(475px down) {
			width: percentage(1 / 2);
			flex: 0 1 auto;

			&:nth-child(4) {
				@include dots-spacer-vertical;
			}

			&:nth-child(3),
			&:nth-child(5) {
				&::before {
					content: '';
					width: 0;
				}
			}
		}
	}

	li:nth-last-child(5):first-child,
	li:nth-last-child(5):first-child ~ li {
		width: percentage(1 / 5);

		@include breakpoint(725px down) {
			width: percentage(1 / 3);
			flex: 0 0 auto;
			margin-bottom: 2rem;

			&:nth-child(4)::before {
				content: '';
				width: 0;
			}
		}

		@include breakpoint(475px down) {
			width: percentage(1 / 2);
			flex: 0 1 auto;

			&:nth-child(4) {
				@include dots-spacer-vertical;
			}

			&:nth-child(3),
			&:nth-child(5) {
				&::before {
					content: '';
					width: 0;
				}
			}
		}
	}

	li:nth-last-child(4):first-child,
	li:nth-last-child(4):first-child ~ li {
		width: percentage(1 / 4);
		margin-bottom: 2rem;

		@include breakpoint(610 down) {
			width: percentage(1 / 2);
			flex: 0 1 auto;

			&:nth-child(3)::before {
				content: '';
				width: 0;
			}
		}
	}

	li:nth-last-child(3):first-child,
	li:nth-last-child(3):first-child ~ li {
		width: percentage(1 / 3);
		margin-bottom: 2rem;

		@include breakpoint(475px down) {
			width: percentage(1 / 2);
			flex: 0 1 auto;

			&:nth-child(3)::before {
				content: '';
				width: 0;
			}
		}
	}

	li:nth-last-child(2):first-child,
	li:nth-last-child(2):first-child ~ li {
		width: percentage(1 / 2);
	}

	// @include breakpoint(small only) {
	// 	flex-flow: wrap;
	//
	// 	li,
	// 	li + li {
	// 		width: 50%;
	// 		&::before {
	// 			content: '';
	// 			width: 0;
	// 		}
	// 	}
	// }
}

.tab-block,
.tab-group {
	.tabs {
		@include strip-list;
		display: flex;
		flex-flow: row nowrap;

		justify-content: center;

		margin-right: auto;
		margin-left: auto;
	}
}

.tab-block {
	$color-tab-block: $white;

	.transformer-tabs {
		.tabs {
			background-color: transparent;
		}

		.tabs-title {
			> a {
				@include font-size('large');

				@include anchor-colors($color-primary, $anchor-color);

				font-weight: bold;
				background-color: transparent;
			}

			&.is-active,
			&.is-active > a {
				background-color: $color-tab-block;
				}
		}

		// breakpoint includes have to come at the end, despite the linter's
		// preferred declaration order
		//scss-lint:disable DeclarationOrder
		@include breakpoint(small only) {
			.tabs {
				display: block;
				position: relative;
				border-bottom: 0;
				overflow: hidden;
				height: 3.5rem;
				background: $color-shade-light;


				&.open {
					height: auto;

					.tabs-title {
						position: relative;
						display: block;

						&::after {
							content: '';
						}

						&:first-child {
							&::after {
								content: '☰';
							}
						}

						> a {
							position: relative;
							display: block;
						}

						// &.is-active {
						// 	&:not(':first-child') {
						// 		&::after {
						// 			content: '';
						// 		}
						// 	}
						// }

					}
				}

			}

			.tabs-title {
				display: block;
				position: absolute;

				float: none;
				height: 3.5rem;
				width: 100%;
				// padding-right: 2rem;
				z-index: 0;

				&:first-of-type,
				&.is-active {
					&::after {
						content: '☰'; /* "Three Line Menu Navicon" shows up */
						color: $white;
						background: linear-gradient(#666, #222);
						position: absolute;
						top: 0;
						right: 0;
						padding-right: 1rem;
						width: 3rem;
						height: 3.5rem;
						line-height: 3.5rem;
						z-index: 2;
						text-align: right;
						pointer-events: none;
					}
				}


				> a {
					@include anchor-colors($color-primary);
					position: absolute; /* Stack links on top of each other */
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}

				&.is-active {
					z-index: 1;

					> a {
						@include anchor-colors($white);
						border: 0;
						z-index: 1; /* Active tab is on top */
						background: linear-gradient(#666, #222);
					}
				}
			}
		}
	}


	.tabs-panel {
		padding: rem-calc(52) rem-calc(52) rem-calc(40) rem-calc(44);
		background-color: $color-tab-block;
	}
}

section.fancy-tabs {
	padding-top: 0;
}

.masthead + section.fancy-tabs {
	> .hgroup,
	> .row > .hgroup,
	.link-row:first-child {
		margin-top: rem-calc(27);
	}
}

.tab-group {

	.tab-strip {
		margin-bottom: rem-calc(50) + $space-page-section;
		padding-bottom: 1.25rem;
		background-color: $color-shade-lighter;
	}

	.tabs {

		max-width: 75rem;

		background-color: transparent;

		.tabs-title {
			display: flex;
			align-items: stretch;

			width: 25rem;
			flex: 1 1 auto;

			text-align: center;

			section > & {
				// margin-top: -1rem;
			}

			.icon-wrapper,
			.tab-title {
				margin-bottom: 0.5rem;
			}

			.icon-wrapper {
				margin-right: auto;
				margin-left: auto;

				font-size: rem-calc(18);
			}

			> a {

				@include font-size('medium');

				position: relative;

				padding-top: rem-calc(32);
				padding-bottom: rem-calc(32);

				width: 100%;
				transition: color $transition-time ease;

				@include hover {
					background-color: transparent;
				}

				&::after {
					content: '';
					display: block;
					position: absolute;
					height: 50px;

					right: 0;
					bottom: -50px;
					left: 0;

					background-color: transparent;
					background-repeat: no-repeat;
					background-size: 100% 100%;

//					background-size: contain;
				}


				&:hover,
				&:active,
				&:focus {
					background-color: $white;

					&::after {
						//scss-lint:disable DuplicateProperty
						background-image: url('images/angled-bottom-white.svg');
						background-image: url('images/angled-bottom-white.svg');
					}
				}

				.tab-description {
					color: $color-text;
				}
			}

			&.is-active {
				> a {

					color: $white;

					background-image: url('images/watercolor.png');
					background-repeat: no-repeat;
					background-position: bottom 50px center;
					background-size: 350px 100%;

					// @include hover {
					background-color: $tab-background-active;
					// }

					.tab-description {
						color: inherit;
					}

					&::after {
						//scss-lint:disable DuplicateProperty
						background-image: url('images/angled-bottom.svg');
					}
				}
			}

		}

	}

	// breakpoint includes have to come at the end, despite the linter's
	// preferred declaration order
	//scss-lint:disable DeclarationOrder
	@include breakpoint(small only) {
		.tabs {
			.tabs-title {
				// width: percentage(1 / 3);

				> a {
					padding: 1rem 0.375rem 2.5rem;
				}
			}

			.tab-title {
				@include font-size('large');
			}

			.tab-description {
				display: none;
			}
		}
	}

	@include breakpoint(425px down) {
		.tabs {
			.tab-title {
				@include font-size('medium');
			}
		}
	}

	.tabs-panel {
		.tabs-panel__intro {
			max-width: rem-calc(720);
			margin-right: auto;
			margin-left: auto;

			text-align: center;
			margin-bottom: rem-calc(72);
		}

// 		.hgroup {
//			text-align: center;
// 		}

		hr.triangles {
			margin-bottom: 4rem;
		}

		section,
		.link-columns {
			margin-bottom: 4rem;
		}
	}

	.tabs-content h3 {
		font-weight: 500;
	}


	.link-columns {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;

		.column {
			margin-bottom: 1.5rem;
			padding-right: 2rem;
			padding-left: 2rem;
			width: 320px;
			flex: 1 1 auto;
		}

		ul {
			@include strip-list;
			@include font-size('large');

			a {
				// font-weight: bold;
			}

			li + li {
				margin-top: 0.75rem;
			}
		}
	}

}

.list-interface {
	@include colored-bg($color-secondary);

	padding-top: 2.5rem;
	padding-bottom: 2.5rem;

	color: $white;

	label {
		@include font-size('large');
		font-weight: bold;
	}

	.button {
		@include hover {
			background-color: $color-tertiary;
		}
	}

	input {
		border: 0;
		height: 2.4375rem;
	}

	.form-inline {
		margin-bottom: 0.5rem;
		flex-flow: row wrap;
		justify-content: flex-end;

		> * {
			margin-bottom: 0.5rem;
		}

		label {
			width: 10rem;
			text-align: right;

			flex: 0 0 auto;
		}

		input {
			padding: 0.5rem rem-calc(40) 0.5rem rem-calc(12);
		}

		input,
		.select-wrapper {
			width: rem-calc(280);
			flex: 1 0 auto;
		}

		select {
			display: block;
		}

		button {
			width: 11rem;
			flex: 0 0 auto;
		}

	}

	+ section {
		padding-top: 0;
	}

}

.listing-table {
	&,
	tbody {
		display: block;
		width: 100%;
	}

	tbody {
		border: solid 1px $color-shade-lighter;
	}

	tr {
		display: flex;
		flex-flow: row wrap;

		justify-content: space-between;
		width: 100%;

		padding: 2rem 2.5rem 1rem 1rem;

		&:nth-child(2n) {
			@include colored-bg($color-shade-lighter);
		}

		// breakpoint includes have to come at the end, despite the linter's
		// preferred declaration order
		//scss-lint:disable DeclarationOrder
		@include breakpoint(760) {
			flex-wrap: nowrap;
		}
	}

	td {
		@include grid-column;

		display: block;
		padding-bottom: 1rem;

		flex: 0 1 auto;

		&.cell-collapse {
			flex: 1 0 auto;
		}
	}

}

.department-listing {
	a {
		font-weight: bold;
	}

	td {
		width: 100%;

		&.cell-collapse {
			width: rem-calc(200);
		}

		// breakpoint includes have to come at the end, despite the linter's
		// preferred declaration order
		//scss-lint:disable DeclarationOrder
		@include breakpoint(760) {
			&,
			&.cell-collapse {
				width: percentage(1 / 3);
			}
		}
	}

	.department__name {
		@include font-size('large');
		font-weight: bold;
	}
}

.people-listing {

	td {
		width: 100%;

		&.cell-collapse {
			width: rem-calc(200);
		}

		// breakpoint includes have to come at the end, despite the linter's
		// preferred declaration order
		//scss-lint:disable DeclarationOrder
		@include breakpoint(760) {
			&,
			&.cell-collapse {
				width: 25%;
			}
		}

		&.column-portrait {
			display: none;

			width: 7rem + rem-calc(42);
			min-width: 0;
			padding: 0 rem-calc(21);

			flex: 0 0 auto;

			@include breakpoint(760) {
				display: block;
			}
		}
	}

	.person__name {
		@include font-size('large');
		font-weight: bold;

		a {
			@include anchor-colors($color-text, $color-text);
		}
	}

	.person__title {
		font-weight: bold;
		color: $color-shade-dark;
	}

	.person__bio-link {
		@include inline-icon;
		font-weight: bold;
	}

	.person__portrait {
		width: 7rem;

		img {
			border-width: 3px;
		}

	}
}

.directory-pagination {
	.pagination-numbers {
		ul {
			justify-content: center;
		}
	}
}

.news-items {
	@include strip-list;

	.news-item {
		@include heading-gamma($scale: 0.9);

		@include inline-icon;

		margin-bottom: 1.5em;

		a {
			@include anchor-colors($color-primary, $anchor-color);
		}

		.icon-wrapper {
			font-size: 0.75em;
		}
	}
}

// Flow-Flow

.social-stream {
	.ff-theme-classic {

		&.ff-style-1 {
			.ff-userpic {
				//scss-lint:disable ImportantRule
				background-image: none !important;
				width: 30px;
				height: 30px;

				.ff-icon {
					position: relative;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

					width: 30px;
					height: 30px;

					.ff-icon-inner {
						&::after {
							width: 30px;
							height: 30px;
							font-size: 18px;
							line-height: 30px;
						}
					}
				}
			}
		}

		&.ff-style-4 {
			.ff-item-meta {
				display: flex;
				flex-flow: row nowrap;

				justify-content: flex-start;

				height: auto;

				> * {
					display: block;
					float: none;

					flex: 1 1 auto;

					height: auto;
				}

				.ff-userpic {
					flex: 0 0 auto;
				}

				.ff-name {
					flex: 0 1 auto;
					// width: calc(100% - 35px);
				}

				.ff-timestamp {
					flex: 1 0 auto;
					// &::before {
					// 	display: none;
					// }
				}
			}
		}
	}
}
