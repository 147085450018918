/*------------------------------------*\
    $MIXINS
\*------------------------------------*/

/// Mixes a color with white.
///
/// @param {Color} $color
///
/// @param {Number (Percentage)} $percent
///   The amount of white to be mixed in.
///
/// @example scss - Usage
///   .element {
///     background-color: tint(#6ecaa6, 40%);
///   }
///
/// @example css - CSS Output
///   .element {
///     background-color: #a8dfc9;
///   }
///
/// @return {Color}
@function tint($color, $percent) {
  @return mix(#fff, $color, $percent);
}

/// Mixes a color with black.
///
/// @param {Color} $color
///
/// @param {Number (Percentage)} $percent
///   The amount of black to be mixed in.
///
/// @example scss - Usage
///   .element {
///     background-color: shade(#ffbb52, 60%);
///   }
///
/// @example css - CSS Output
///   .element {
///     background-color: #664a20;
///   }
///
/// @return {Color}
@function shade($color, $percent) {
  @return mix(#000, $color, $percent);
}

/// Map deep get
/// @author Hugo Giraudel
/// @access public
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Key chain
/// @return {*} - Desired value
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function url-safe($string) {
  @return str-replace($string, '#', '%23');
}

// generate the image url by prefixing the layout image path
@function image-url($url) {
  @return url("#{$image-url-path}#{$url}");
}

// All headings, or a range of them
@mixin headings($from: 1, $to: 6) {
  @for $i from $from through $to {
    $named-headings: 'alpha''beta''gamma''delta''epsilon';

    @if ($i <= length($named-headings)) {
      .heading--#{nth($named-headings, $i)} {
        @content
      }
    }

    h#{$i} {
      @content
    }
  }
}

@mixin hover {

  &:active,
  &:focus,
  &:hover {
    @content;
  }
}

// Set behavior for anchors in standard and activated states
@mixin anchor-colors($color, $color-hover:false) {
  @if ($color-hover==false) {
    $color-hover: lighten($color, 10%);
  }

  &,
  &:link,
  &:visited {
    color: $color;
  }

  @include hover {
    color: $color-hover;
  }
}

@mixin dots-spacer-vertical {
  position: relative;
  padding-left: rem-calc(7);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    display: inline-block;
    height: 100%;
    width: rem-calc(7);
    //scss-lint:disable DuplicateProperty
    background-image: url('images/sketchy-dots-vertical-gray-light.svg');

    //		background-repeat-x: no-repeat;
    background-repeat: repeat-y;
    background-size: auto;
    background-position: left center;
  }

}

@mixin inline-icon {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;

  >* {
    display: block;
  }
}

@mixin inline-icon($side: left, $size: 1em, $color: inherit) {
  .icon-wrapper {
    position: relative;
    display: inline-block;

    top: 0.125 * $size;

    width: $size;
    height: $size;

    color: $color;

    @if ($side==right) {
      margin-right: 0;
      margin-left: 0.375em;
    }

    @else {
      margin-right: 0.375em;
      margin-left: 0;
    }
  }
}

// Puts underlines beneath anchors with this style applied.
// $state :	Does underline appear always or only on hover
//					Options: 'all' | 'hover'
// $style :	Use normal text underlining or bottom-border
//					Options: '' | 'border'
// $border-style : style, width, and color of the
//									bottom-border
// $padding : offset of the underline (only
//										works for bottom-border)
// $color, $color-hover : override the color set in
//												$border-style (if necessary)
@mixin anchor-underline($state:'hover', $style:'', $border-style: $border-med, $padding: 0, $color:'curentColor', $color-hover: false) {
  @if ($color-hover==false) {
    $color-hover: $color;
  }

  @if ($style=='border') {
    @if ($state !='hover') {

      &,
      &:link,
      &:visited {
        border-bottom: $border-style;
        border-bottom-color: $color;
        padding-bottom: $padding;
      }
    }

    @include hover {
      border-bottom: $border-style;
      border-bottom-color: $color-hover;
      padding-bottom: $padding;
    }

  }

  @else {
    @if ($state !='hover') {

      &:link,
      &:visited {
        text-decoration: underline;
      }
    }

    @include hover {
      text-decoration: underline;
    }
  }
}

// Formatting for lists of links
@mixin link-list($bullet-font-size:$list-bullet-font-size, $bullet-spacing:$list-bullet-spacing, $link-font-size:$link-list-font-size, $pad-vertical:9) {

  // For links not in an <li> tag, if .link-list is not a UL
  >a {
    display: block;
    padding-top: rem-calc($pad-vertical);
    padding-bottom: rem-calc($pad-vertical);
  }

  li {
    margin-left: $bullet-font-size + rem-calc($bullet-spacing);
    border-bottom: $border-med;
    padding-top: rem-calc($pad-vertical);
    padding-bottom: rem-calc($pad-vertical);
    padding-left: $bullet-font-size + rem-calc($bullet-spacing);

    text-indent: -($bullet-font-size + rem-calc($bullet-spacing));

    font-family: $font-secondary;
    font-size: 0;
    font-weight: 400;

    &::before,
    &::after,
    >* {
      font-size: $link-list-font-size;
    }

    &::before {
      display: inline-block;
      color: $color-text;
    }
  }
}

// Lists split into columns
@mixin list-columns($column-count:3, $direction:'down') {
  >li {
    display: block;
    margin-right: 1.5rem;
  }

  @if ($direction=='across') {
    display: flex;
    flex-flow: row wrap;
    flex: 1 0 auto;

    >li {
      margin-bottom: $space;
      padding-left: $pad;

      width: calc(#{percentage(1 / $column-count)} - #{$list-bullet-font-size + rem-calc($list-bullet-spacing)});

      &:nth-child(#{$column-count}n-#{$column-count - 1}) {
        padding-left: 0;
      }
    }

  }

  @else {
    display: block;
    columns: $column-count;
    column-gap: 1rem;

    >li {
      break-inside: avoid;
    }
  }

}

@mixin colored-bg ($bg-color, $text-color: false, $link-colors: false) {
  @if ($text-color==false) {
    @if (lightness($bg-color) < 55) {
      $text-color: $white;

      @include headings() {
        color: $white;

        &.heading--two-tone {
          color: $color-accent-tertiary;

          b {
            color: $white;
          }
        }
      }

      a {
        @include anchor-colors($color-quinary, $color-accent-quinary);

        @if ($link-colors) {
          @if (length($link-colors)==1) {
            @include anchor-colors($link-colors);
          }

          @else {
            @include anchor-colors(nth($link-colors, 1), nth($link-colors, 2));
          }
        }

        @else {
          @include anchor-colors($color-quinary, $color-tertiary);
        }
      }

    }

    @else {
      $text-color: $color-text;
    }
  }

  color: $text-color;
  background-color: $bg-color;
}

// style input placeholders
// Linting disabled because this is a hack that requires non-standard
// syntax.
// scss-lint:disable PseudoElement, VendorPrefix
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &:-moz-placeholder {
    @content
  }

  &::-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }
}

@mixin logo-color ($color: $color-brand, $bg-color: $white) {
  color: $color;
  background-color: $bg-color;
}

@mixin match-height {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  &.row {

    .column,
    .columns {
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }
  }
}

@mixin size($value) {
  $width: nth($value, 1);
  $height: $width;

  @if length($value)>1 {
    $height: nth($value, 2);
  }

  @if is-size($height) {
    height: $height;
  }

  @else {
    @warn"`#{$height}` is not a valid length for the `$height` parameter in the `size` mixin.";
  }

  @if is-size($width) {
    width: $width;
  }

  @else {
    @warn"`#{$width}` is not a valid length for the `$width` parameter in the `size` mixin.";
  }
}

@mixin image-circle($border-color: $color-secondary, $border-width-outer: 2px, $border-width-inner: 4px) {

  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: calc(100% - #{$border-width-outer * 2});

  max-width: 100%;

  border: solid $border-color $border-width-outer;
  border-radius: 50%;
  //	overflow: hidden;
  //	height: 0;

  margin: 0;
  //	padding-top: 100%;

  >img {
    position: absolute;
    border: solid $white $border-width-inner;
    border-radius: 50%;
    top: 0;
    left: 0;

    width: 100%;
  }

}

@mixin underline($color:currentColor, $bg-color: $white, $line-offset:1.09, $line-size:2, $stroke: 1) {
  background-repeat: repeat-x;
  background-size: #{$line-size}em #{$line-size}px;
  background-position: 0 #{$line-offset}em;
  text-decoration: none;
  background-image: linear-gradient(to top, transparent 50%, $color 50%);

  text-shadow: 0 #{$stroke}px $bg-color,
  0 #{-1 * $stroke}px $bg-color,
  0 #{$stroke}px $bg-color,
  #{-1 * $stroke}px 0 $bg-color;
}

@mixin shadow($color: #000, $opacity: 0.25, $width: 80%, $height: 0.25rem, $radius: 50%) {
  // TODO:
  // * Allow choice of side
  position: relative;

  &::after {
    position: absolute;
    bottom: -$height;
    left: 50%;
    transform: translateX(-50%);

    opacity: $opacity;

    z-index: -1;

    border-radius: $radius;

    box-shadow: 0 0 14px 14px $color;

    background-color: $color;

    width: $width;
    height: 3rem;

    content: '';
  }
}

@mixin dash-gradient($dash-color, $dash-length, $gap-length, $direction:'horizontal') {
  $dash-full-width: 100% !default;
  $dash-full-width: $dash-length + $gap-length;
  $dash-percent: percentage($dash-length / $gap-length);
  $todir: 'right' !default;
  $repeat: 'repeat-x' !default;
  $pos: 'bottom' !default;
  $bs: '#{$dash-full-width}px 100%' !default;

  @if $direction=='vertical' {
    $todir: 'to bottom';
    $repeat: 'repeat-y';
    $pos: 'right';
    $bs: '100% #{$dash-full-width}px';
  }

  background-color: transparent;
  background-image: linear-gradient(to #{$todir}, $dash-color 45%, rgba($dash-color, 0) 0%);
  background-position: #{$pos};
  background-size: #{$bs};
  background-repeat: #{$repeat};
}

@mixin ornament-above {
  margin-top: $ornament-size / 2;
  padding-top: $ornament-size / 2;
  position: relative;

  .ornament {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

@mixin ornament-below {
  margin-bottom: $ornament-size / 2;
  padding-bottom: $ornament-size / 2;
  position: relative;

  .ornament {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
  }
}

@mixin strip-list {
  // Strip Unordered List
  // Removes all margins and bullets
  // to use a list for layout instead of editorial/text/content
  margin: 0;

  >li {
    margin-left: 0;
    text-indent: 0;

    &::before {
      display: none;
    }
  }
}

// Stretch the given element to fill the nearest ancestor with
// a display of "absolute" or "relative"
@mixin pseudo-cover ($beneath:false, $fill: 'both', $pin:'center''top', $flex: false) {
  width: 100%;
  max-width: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  //	content: '';
  @if ($flex) {
    display: flex;
  }

  @else {
    display: block;
  }

  margin: 0;

  @if $beneath {
    z-index: 0;
  }

  >img {
    position: absolute;

    max-width: none;
    max-height: none;

    @if ($fill=='width') {
      width: 100%;
      height: auto;
      min-height: none;
    }

    @else if ($fill=='height') {
      width: auto;
      min-width: none;
      height: 100%;
    }

    @else {
      width: auto;
      min-width: 100%;
      height: auto;
      min-height: 100%;
    }

    @if(nth($pin, 1)=='center') {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }

    @else if(nth($pin, 1)=='right') {
      right: 0;
      left: auto;
      transform: none;
    }

    @else {
      right: auto;
      left: 0;
      transform: none;
    }

    @if(nth($pin, 2)=='center') {
      top: 50%;

      @if(nth($pin, 1)=='center') {
        transform: translate(-50%, -50%);
      }

      @else {
        transform: translateY(-50%);
      }
    }

    @else {
      top: 0;
      bottom: 0;
    }
  }
}

// Applies a semi-transparent layer on top of a pseudo-covered image
// to make it darker
@mixin color-overlay($color:#000, $opacity:$opacity-overlay, $mode:multiply) {
  &::after {
    @include pseudo-cover;
    transform: translate3d(0, 0, 0);
    background-color: rgba($color, $opacity);
    content: '';
  }

  >img {
    z-index: 0;
  }

  .mix-blend-mode & {
    >img {
      opacity: $opacity * 0.5;
    }

    &::after {
      background-color: $color;
      mix-blend-mode: $mode;
      z-index: 1;
    }
  }
}

// Simple variant of color overlay. Darkens the contents of an element
// by placing a semi-transparent black (or specified color) layer on
// top of it.
@mixin dark-bg-image($color:#000, $opacity:$opacity-overlay) {
  &::after {
    @include pseudo-cover;
    background-color: rgba($color, $opacity);
    content: '';
  }
}

// Applies a semi-transparent layer on top of a pseudo-covered image
// to make it darker
@mixin gradient-overlay($color:#000, $direction:to right, $distance:100%, $midpoint: 0%, $opacity:0) {
  &::after {
    @include pseudo-cover;
    background-image: linear-gradient($direction, $color, $color $midpoint, rgba($color, $opacity) $distance);
    content: '';
  }
}

// Background image of a horizontal line, made up of dots.
// Arguments:
//		$size[pixel value] default: set in variables file
//			Size of a single dot in pixels
//
//		$spacing[float]	default: set in variables file
//			Size ratio of space between dots to dot size. Larger value means bigger space between dots.
//
//		$color[color hex value] default: set in variables file
//			Color of dots
@mixin line-dotted($size:$border-dot-size, $spacing:$border-dot-spacing, $color:$border-dot-color) {

  $radius: $size / 2;
  $space-width: $spacing * $size;

  $image-horizontal: 'data:image/svg+xml;utf8,<svg width=\'#{$size + $space-width}\' height=\'#{$size}\' viewBox=\'0 0 #{$size + $space-width} #{$size}\' xmlns=\'http://www.w3.org/2000/svg\'><ellipse fill=\'#{$color}\' cx=\'#{$radius}\' cy=\'#{$radius}\' rx=\'#{$radius}\' ry=\'#{$radius}\'/></svg>';

  background-image: url($image-horizontal);
  background-repeat: repeat-x;
  background-position: center center;
  background-size: #{$size + $space-width}px auto;

}

@mixin content-circle ($stroke:$color-primary 8, $fill:'transparent') {

  background-image: url-safe(url("data:image/svg+xml,%3Csvg%20stroke%3D%27#{nth($stroke, 1)}%27%20stroke-width%3D%27#{nth($stroke, 2)}px%27%20fill%3D%27#{$fill}%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2775%27%20height%3D%2775%27%20viewBox%3D%270%200%2075%2075%27%20preserveAspectRatio%3D%27xMinYMin%27%3E%3Cellipse%20cx%3D%2737.5%27%20cy%3D%2737.5%27%20rx%3D%2730%27%20ry%3D%2730%27%20%2F%3E%3C%2Fsvg%3E"));
  background-size: 100%;
  content: '';
}

// Wrapper rules for an image and text where the image replaces
// the text
@mixin text-replace {
  display: inline-block;
  font-size: 0;
}

@mixin border-dotted($size:$border-dot-size, $spacing:$border-dot-spacing, $color:$border-dot-color, $sides:$dotted-border-sides-default) {
  $radius: $size / 2;
  $space-width: $spacing * $size;

  $image-size: $size * 3 + $space-width * 2;

  $image-border: 'data:image/svg+xml;utf8,<svg width=\'#{$image-size}\' height=\'#{$image-size}\' viewBox=\'0 0 #{$image-size} #{$image-size}\' xmlns=\'http://www.w3.org/2000/svg\'><g fill=\'#{$color}\'><ellipse cx=\'#{$radius}\' cy=\'#{$radius}\' rx=\'#{$radius}\' ry=\'#{$radius}\'/><ellipse cx=\'#{$size + $space-width + $radius}\' cy=\'#{$radius}\' rx=\'#{$radius}\' ry=\'#{$radius}\'/><ellipse cx=\'#{($size * 2) + $space-width * 2 + $radius}\' cy=\'#{$radius}\' rx=\'#{$radius}\' ry=\'#{$radius}\'/><ellipse cx=\'#{$radius}\' cy=\'#{$size + $space-width + $radius}\' rx=\'#{$radius}\' ry=\'#{$radius}\'/><ellipse cx=\'#{($size * 2) + $space-width * 2 + $radius}\' cy=\'#{$size + $space-width + $radius}\' rx=\'#{$radius}\' ry=\'#{$radius}\'/><ellipse cx=\'#{$radius}\' cy=\'#{($size * 2) + ($spacing * $size * 2) + $radius}\' rx=\'#{$radius}\' ry=\'#{$radius}\'/><ellipse cx=\'#{$size + $space-width + $radius}\' cy=\'#{($size * 2) + $space-width * 2 + $radius}\' rx=\'#{$radius}\' ry=\'#{$radius}\'/><ellipse cx=\'#{($size * 2) + $space-width * 2 + $radius}\' cy=\'#{($size * 2) + $space-width * 2 + $radius}\' rx=\'#{$radius}\' ry=\'#{$radius}\'/></g></svg>';

  border-width: 0;
  border-style: solid;
  border-image: url($image-border) 33% round;

  @each $side in $sides {
    border-#{$side}-width: #{$radius + $space-width}px;
  }

}

@mixin custom-table($stripe: $table-stripe, $nest: false, $border: $table-border, $background: $table-background, $padding: $table-padding, $head-background: $table-head-background, $head-font-color: $table-head-font-color, $striped-background: false, $color-scale: $table-color-scale, $foot-background: false) {
  width: 100%;
  margin-bottom: $global-margin;
  border-radius: $global-radius;

  @if ($striped-background==false) {
    $striped-background: smart-scale($background, $color-scale);
  }

  @if $nest {
    @include -custom-table-children-styles($stripe, $border, $background, $padding, $head-background, $head-font-color, $striped-background, $foot-background);
  }

  @else {
    @at-root {
      @include -custom-table-children-styles($stripe, $border, $background, $padding, $head-background, $head-font-color, $striped-background, $foot-background);
    }
  }
}