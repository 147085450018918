.site-footer {
	@include colored-bg($color-primary);

	a {
		@include anchor-colors($anchor-color, $color-quinary);

		[href^="tel"] {
			color: inherit;
		}
	}
}

.off-canvas-wrapper {
	background-color: $color-secondary;
}

.off-canvas {
	padding: 1rem;

	.close-button {
		@include inline-icon;
		@include font-size("small");
		position: relative;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		margin-bottom: 1.75rem;

		text-transform: uppercase;

		color: $color-primary;

		@include hover {
			text-decoration: underline;
		}
	}

	.off-canvas--header {
		@include inline-icon;
		display: flex;
		flex-flow: row nowrap;

		align-items: center;

		margin-bottom: 1.75rem;

		font-weight: normal;

		.icon-wrapper {
			display: block;
			top: 0;

			width: 0.75em;
			height: 0.75em;
		}

		.bookmark {
			margin-right: 0.25em;
		}
	}

	.menu--utility {
		li {
			margin-bottom: 1rem;

			// a {
			// 	@include button-style($color-primary, $color-secondary, $white);
			// 	@include font-size("small");
			// 	@include anchor-colors($white);
			// 	display: block;

			// 	padding: 0.5rem 0.75rem;

			// 	font-weight: bold;
			// 	text-transform: uppercase;

			// 	.icon-wrapper {
			// 		color: inherit;
			// 	}
			// }
		}
	}

	&.menu-main--offcanvas {
		background-color: $color-secondary;

		a:not(.nav-main-link) {
			@include anchor-colors($color-primary, $color-primary);

			@include hover {
				text-decoration: underline;
			}
		}

		nav {
			.menu--main {
				@include font-size("large");

				.form--search {
					@include font-size("small");
					width: 100%;

					input[type="search"] {
						margin-right: -1.75em;
						padding-right: 1.75em;
						width: 100%;
					}

					button {
						width: 1.75em;
						height: 1.75em;
						color: $color-primary;
					}
				}
			}
		}

		.link-home {
			display: none;
		}
	}

	&.menu-quicklinks {
		min-height: 100vh;

		background-color: $color-quaternary;

		.close-button {
			@include hover {
				// color: $color-secondary;
			}
		}

		section {
			padding: 1rem 0 0.5rem;

			border-bottom: $border-med;
			border-color: $white;

			&:last-child {
				border-bottom: 0;
			}

			.quicklinks__section-header,
			li {
				margin-bottom: 0.75rem;
			}

			.quicklinks__section-header {
				font-weight: bold;
			}

			ul {
				@include strip-list;

				a {
					@include anchor-colors($color-primary, $color-primary);
					@include hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.site-footer {
	.footer__content {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;

		padding-right: 1rem;
		padding-top: 1.5rem;
		padding-left: 1rem;

		max-width: calc(77rem);

		width: 100%;

		> * {
			margin-bottom: 1.5rem;
		}

		.footer__info,
		.footer__logo {
			margin-bottom: 1.5rem;

			//			width: 18rem;

			//			margin-right: auto;
			//			margin-left: auto;

			flex: 1 1 auto;
		}

		.footer__logo {
			margin-left: auto;
			margin-right: auto;
			min-width: rem-calc(75);
			max-width: rem-calc(150);

			img {
				margin-top: -10%;
			}

			@include breakpoint(menu-medium) {
				margin-right: 1rem;
			}
		}

		.utility-nav {
			@include strip-list;
			margin-bottom: 1.5rem;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-end;
			align-content: center;

			flex: 1 1 auto;

			li {
				margin-right: 0.5rem;
				margin-left: 0.5rem;

				text-transform: uppercase;

				a {
					@include inline-icon;
					@include anchor-colors($white, $anchor-color);
					@include hover {
						.icon-wrapper {
							color: $color-quinary;
						}

						.icon-wrapper {
							color: $anchor-color;
						}
					}

					.icon-wrapper {
						font-size: 1.125em;
						vertical-align: -1px;

						transition: color $transition-time ease;
					}
				}
			}
		}

		.footer__copyright {
			@include font-size("small");
		}

		.social-links {
			display: flex;
			flex-flow: row nowrap;
			align-content: center;

			flex: 0 0 auto;

			.footer--icon {
				display: flex;
				//				align-items: center;

				font-size: 0.75rem;
				margin-right: 0.5rem;
				margin-left: 0.5rem;
			}
		}
	}
}
