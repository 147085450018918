// /* Accessibility *//

// Show for screen readers only
.sr {
	// scss-lint:disable DuplicateProperty
	position: absolute;
	border: 0;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
	clip: rect(1px, 1px, 1px, 1px);
}
