.subheader:not([class*="text-"]) {
	@include heading-delta($color: $anchor-color);
}

.superheader:not([class*="text-"]) {
	@include heading-epsilon($color: $color-shade-dark, $weight: "normal");

	.hgroup & {
		margin-bottom: rem-calc(22);
	}
}

cite {
	color: inherit;
	font-size: inherit;
}

.quote__text {
	display: inline;
}

.social-media-quote {
	.quote__citation {
		display: inline-block;
	}
}

.social-citation {
	@include inline-icon;
}

.date-block {
	.date {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;

		width: rem-calc(55);

		padding: 0.5rem 0.5rem 0.75rem;

		text-align: center;
	}

	.date__day,
	.date__month {
		display: block;
		line-height: 0.75em;
	}

	.date__month {
		@include font-family($font-secondary);
		font-size: 0.625em;
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.25em;
		margin-bottom: 0.45rem;
	}

	.date__day {
		font-size: 2em;
		font-weight: bold;
		letter-spacing: -0.1em;
	}
}

.contact-info {
	a {
		font-weight: bold;
	}
}

.contact--icon {
	@include inline-icon($size: 1.5em, $color: $color-shade-dark);

	&.facebook,
	&.flickr,
	&.linkedin,
	&.twitter,
	&.youtube {
		@include inline-icon($size: 1.5em, $color: $white);

		.icon-wrapper {
			border-radius: 50%;
			background-color: $color-shade-dark;

			svg {
				width: 1.375em;
				height: 1.375em;
				margin-top: 0.0625em;
				margin-left: 0.0625em;
			}
		}
	}
}

.footer--icon {
	display: block;

	.icon-wrapper {
		color: transparent;
		border-radius: 50%;

		svg {
			fill: $anchor-color;
		}
	}
}

a.footer--icon {
	@include hover {
		.icon-wrapper {
			svg {
				fill: $color-quaternary;
			}
		}
	}

	.icon-wrapper {
		svg {
			fill: $color-secondary;
			transition: fill $transition-time ease;
		}
	}
}

.rich-text {
	overflow: auto;

	.aside + & {
		overflow: visible;
	}

	> * {
		max-width: 100%;
	}

	ol,
	ul {
		padding-left: 1em;

		li {
			margin-bottom: 0.375em;
		}
	}

	// prevent tops of headers from getting cut off due to negative margin-tops applied in `src/scss/global/_fonts.scss`
	> h1:first-child,
	> h2:first-child,
	> h3:first-child,
	> h4:first-child,
	> h5:first-child,
	> h6:first-child {
		padding-top: 0.15rem;
	}
}

.text-centered {
	text-align: center;
}
//.phone {
//	font-weight: bold;
//}
