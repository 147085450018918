//body > * > * {
//	padding-right: 1rem;
//	padding-left: 1rem;
//}

.full-page-width {
	padding-right: 0;
	padding-left: 0;
}

.page-background-image {
	padding: 0;
}

@mixin page-narrow {
	max-width: calc(#{rem-calc(860)} + #{map-get($grid-column-gutter, small)});
}

.page--narrow {
	.row {
		@include page-narrow;
	}
}

// Auto-width columns
.column--auto {
	$max-columns: 12;
	margin-bottom: $paragraph-margin-bottom * 2;

	@include breakpoint(medium) {
		@include grid-column(12);
	}

	@include breakpoint(large) {
		@for $i from 1 through $max-columns {
			&:nth-last-child(#{$i}):first-child,
			&:nth-last-child(#{$i}):first-child ~ .column,
			&:nth-last-child(#{$i}):first-child ~ .columns {
				@include grid-column($grid-column-count / $i);
			}
		}
	}
}

.row--expanded {
	max-width: none;
}

.row--gutter-reduced {
	padding-right: map-get($grid-column-gutter, small) / 4;
	padding-left: map-get($grid-column-gutter, small) / 4;
}

.row.row--no-gutter-outer {
	max-width: $global-width + map-get($grid-column-gutter, small) / 4;
	padding-right: 0;
	padding-left: 0;
}

//.column-block-4 + .column-block-4 {
//	margin-top: $paragraph-margin-bottom * 2;
//}


.column,
.columns {
	&.column--gutter-reduced.column.column {
		padding-right: map-get($grid-column-gutter, small) / 8;
		padding-left: map-get($grid-column-gutter, small) / 8;
	}

	&.column--gutter-wide.column.column {
		padding-right: map-get($grid-column-gutter, small) * 2;
		padding-left: map-get($grid-column-gutter, small) * 2;
	}

	&.column--gutter-none {
		//scss-lint:disable ImportantRule
		padding-right: 0 !important;
		padding-left: 0 !important;

		> .row {
			margin-right: 0;
			margin-left: 0;
		}

		.row .row &:first-child {
			padding-left: map-get($grid-column-gutter, small) / 2 !important;
		}

		.row .row &:last-child {
			padding-right: map-get($grid-column-gutter, small) / 2 !important;
		}
	}
}

.flex-row {
	display: flex;

	flex-flow: column nowrap;

	.column--stretch {
		display: flex;
		flex-flow: row nowrap;
		align-items: stretch;
	}

	//scss-lint:disable DeclarationOrder
	@include breakpoint(medium down) {
		.column {
			margin-bottom: 1rem;
		}
	}

	@include breakpoint(medium) {
		flex-flow: row wrap;

		.column {
			@include grid-column(12);
		}

		.column--largest {
			@include grid-column(24);
		}
	}

	@include breakpoint(large) {
		.column,
		.column-largest {
			@include grid-column(8);
		}
	}

}

.fill-height {
	display: flex;
	align-items: stretch;
}


.max-page-width {
	max-width: $global-width;
	margin-right: auto;
	margin-left: auto;
	padding-left: $pad;
	padding-right: $pad;
}

.has-sidebar {
	display: flex;
	flex-flow: row wrap;

	@include breakpoint(large down) {
		padding-right: 0;
		padding-left: 0;
	}

	@include breakpoint(large) {
	//	padding-right: 0;
		padding-left: 0;
	}

	> .header--page {
		@include grid-column(24);
		flex: 0 0 auto;
	}

	&:not(.row) {
		max-width: $global-width;
		margin-right: auto;
		margin-left: auto;
	}

	> .content--main,
	> .sidebar {
		width: 100%;
	}

	> .content--main {

		padding-right: 2rem;
		padding-left: 2rem;

		@include breakpoint(large) {
			@include grid-column(17);
			padding-right: map-get($grid-column-gutter, small) / 2;
	//		padding-left: 0;

			&.page-intro {
				@include grid-column(15);
			}
		}

	}

	> .sidebar {
		padding: 0;

		> section {
			padding-top: 0;
			padding-right: $pad;
			padding-bottom: $pad;
			padding-left: $pad;

			&.section--dark,
			&.bg-secondary {
				padding-top: $pad;
			}

			//scss-lint:disable DeclarationOrder
			@include breakpoint(large) {
				padding: 0;

				&.section--dark,
				&.bg-secondary {
					padding: $pad;
				}
			}

		}


		> section > header,
		.section__header {
			margin-bottom: $paragraph-margin-bottom;
		}

		//scss-lint:disable DeclarationOrder
		@include breakpoint(large) {
			@include grid-column(7);
			padding-left: map-get($grid-column-gutter, small) / 2;
		}

	}

	//scss-lint:disable DeclarationOrder
	@include breakpoint(large) {
		.page-intro + .sidebar {
			@include grid-column(9);
		}
	}

//	&.has-sidebar--narrow {
//		@include grid-row(30);
//
//		> .content--main {
//			@include grid-column(22);
//		}
//
//		> .sidebar {
//		@include grid-column(8);
//		}
//
//	}
}

.match-height {
	@include match-height;
}

.row.center-vertical {
	display: flex;
	align-items: stretch;

	.column {
		display: flex;
		flex-flow: column nowrap;

		justify-content: center;
//		align-items: flex-start;
	}
}

*[class*="column-block"] {
	@include match-height;
	flex-wrap: wrap;
	justify-content: center;

	margin-bottom: -$paragraph-margin-bottom;

	> header {
		width: 100%;
	}
}

@include breakpoint(medium only) {
	.column-block-3 {
		.column--auto {
			width: 100%;
			display: block;
			position: relative;
			min-height: rem-calc(205);

			.teaser__image {
				width: 33%;
				position: absolute;
				top: 0.375rem;
				left: 0;
			}

			.teaser__heading-group,
			.teaser__blurb,
			.teaser__links {
//				float: right;
				margin-left: calc(33% + 1rem);
			}

		}
	}
}

// fix svg aspect ratio in IE 10/11, as described here: https://gist.github.com/larrybotha/7881691#target-ie-with-css
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%; 
  }
}
