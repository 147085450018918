/* -------------------------------------------------------------- */
/*    Molecules - Text
/*
/*    TODO:
/*		- put_todo_items_here
/* -------------------------------------------------------------- */

/*
/* Pullquote
/* _______________________________________________________________ */
//.pullquote {
//	margin: 1rem 0;
//	padding: 0;
//	color: $color-brand;
//	position: relative;
//	border: none;
//	p {
//		margin-left: $pullquote-margin;
//		color: inherit;
//		font-size: rem-calc(20);
//		margin-bottom: $space-half;
//	}
//	cite {
//		margin-left: $pullquote-margin;
//		color: inherit;
//		font-style: italic;
//	}
//	&:before {
//		position: absolute;
//		margin-top: .3rem;
//		margin-left: 0;
//		display: block;
//		@extend .fa;
//		content: $fa-var-quote-left;
//		background-color: $color-brand;
//		color: $white;
//		border-radius: 50%;
//		font-size: rem-calc(30);
//		height: rem-calc(60);
//		width: rem-calc(60);
//		line-height: rem-calc(60);
//		text-align: center;
//	}
//}

.pullquote {
	@include heading-gamma();

	border: $border-med;
	padding: rem-calc(40);

	text-align: center;

	.citation {
		@include heading-epsilon($color: $color-shade);
		padding-top: 1em;
	}

	p {
		&::before {
			content: '“';
		}

		&:last-of-type {
			margin-bottom: 0;

			&::after {
				content: '”';
			}
		}
	}

	&.pullquote--news {
		border: $border-thin;
		border-color: $black;
		border-left-width: 0;
		border-right-width: 0;
		color: $color-shade-darker;
		font-family: $font-primary !important;
		font-size: rem-calc(22) !important;
		font-style: italic;
		font-weight: bold;
		line-height: rem-calc(27) !important;
		padding: rem-calc(25);
		margin: rem-calc(25 0);
		text-align: left !important;
		background-image: url('images/quotation_marks.svg');
		background-repeat: no-repeat;
		background-position-y: rem-calc(22);
	}
}


.row,
main {
	> .hgroup,
	> .heading,
	> h1,
	> h2,
	> h3 {
		&:not(.heading--left) {
			text-align: center;
		}

		&.heading--left,
		.heading--left > & {
			text-align: left;
		}
	}
}

.hgroup {
	margin-bottom: rem-calc(27);

	&.sketchy-arrow {
		display: flex;
		position: relative;

		flex-flow: column nowrap;

		justify-content: flex-start;

		max-width: calc(100% - #{rem-calc(80)});
		margin-right: auto;
		margin-left: auto;

		align-items: center;

		margin-bottom: rem-calc(48);

		h1,
		h2,
		h3 {
			position: relative;

			.icon-wrapper {
				position: absolute;
				top: 30%;
				left: calc(100% + 0.5rem);
				width: rem-calc(40);
				height: rem-calc(40);
				color: $color-quinary;
			}
		}
	}

	&.hgroup--alt {
		h2 {
			@include heading-alpha($weight: normal, $transform: none);
		}
	}
	&.hgroup--newsroom {
		margin-bottom: 0 !important;
		h1 {
			text-align: left !important;
			font-size: 2.5rem !important;
			margin-bottom: 1rem !important;
			//margin-bottom: 0 !important;
			margin-top: 0 !important;
			float: none;

			@include breakpoint(820) {
				display: inline;
				float: left;
			}
		}

		/* div.row.column {
			padding-left: 1.5rem;
			@include breakpoint(medium) {
				padding-left: 1.5rem !important;
			}
			@include breakpoint(1260px) {
				padding-left: 0.5rem !important;
			}
		} */
	}
}

.hgroup,
.hgroup > .row {
	> *:last-child {
		margin-bottom: 0;
	}

}

.contact-info {
	.address + .phone {
		margin-top: 0.75rem;
	}
}


.heading--news {
	.subheader {
		color: $color-shade-darker;
		font-size: rem-calc(20) !important;
		margin-top: rem-calc(22) !important;
		text-transform: none !important;
		/* &:hover, &:active {
			color: $color-teal;
		} */
	}
}
