.slick-slide {
	position: relative;

	> * {
		position: relative;
	}

//	figure {
//		@include pseudo-cover(true);
//	}

	.slide-text {
		padding: 1rem;
		color: $white;
		background-color: rgba(0, 0, 0, 0.5);
	}
}

.slick-slider {
	&.slick-dotted {
		margin-bottom: 0;
	}
}

.slideshow-wrapper {
	position: relative;
	padding: 0 1.5rem;

	&.has-pagination {
		padding-bottom: 1.75rem;
	}
}

.slick-buttons,
.slick-dots-container {
	position: absolute;


	height: 2em;
	left: 0;
	right: 0;
	bottom: 1rem;
//	border: solid blue 1px;
}

.slick-dots-container {
	&.pagination-numbers {
		position: absolute;
		right: auto;
		left: 50%;

		.slick-arrow {
			position: absolute;
			top: 0;
		}

		.slick-prev {
			left: 0;
			transform: translateX(-100%) translateX(-0.75em);
		}

		.slick-next {
			right: 0;
			transform: translateX(100%);
		}

	}

	.slick-dots-container.pagination-numbers {
		position: absolute;
		bottom: 0;
	}
}

.slick-buttons {
	top: 50%;
	transform: translateY(-50%);

	.slick-prev,
	.slick-next {
		width: rem-calc(48);
		height: rem-calc(48);

		border-radius: 50%;

		color: $white;
		background-color: $color-quinary;

		transition: background-color $transition-time ease-out;

		@include hover {
			color: $white;
			background-color: darken($color-quinary, 25%);
		}

		&::before {
			display: none;
		}

		svg {
			width: 40%;
			display: inline-block;
		}
	}

	.slick-prev {
		left: 0;
		right: auto;
		// transform-origin: 50% 25% 0;
		transform: rotate3d(0, 0, 1, 180deg) translateY(50%);

		// &.arrow-right svg {
		//
		// }
	}

	.slick-next {
		right: 0;
		left: auto;
	}

}

.slideshow--match-height {
	.slick-track {
		display: flex;
		flex-flow: row nowrap;

		align-items: stretch;

		.slick-slide {
			height: auto;

		}
	}

}

.slideshow--center-vertical {
	.slick-slide {
		display: flex;
		flex-flow: column nowrap;
	}
}

.slideshow-wrapper--cutoff {
	overflow: hidden;
	padding-right: 0;
	padding-left: 0;

	.slick-buttons {
		margin-right: 1rem;
		margin-left: 1rem;

		max-width: rem-calc(860) + 3rem;

		@include breakpoint(896px) {
			margin-right: auto;
			margin-left: auto;
		}
	}

}

.slideshow--large-photos {
	@include breakpoint(896px) {
		width: rem-calc(2680);
		margin-right: auto;
		margin-left: auto;
		position: relative;
		left: 50%;
		transform: translateX(-50%);

		.slick-slide {
//		margin-right: 0.5rem;
//		margin-left: 0.5rem;
			transition: opacity $transition-time ease-in-out,
				transform $transition-time ease-in-out;
		}

		.slick-slide {
			opacity: 0.5;
			transform: scale(0.85) translateX(5%);
		}

		.slick-center {
			opacity: 1;
			transform: scale(1);

			+ .slick-active,
			+ .slick-cloned {
				opacity: 0.5;
				transform: scale(0.85) translateX(-5%);
			}
		}

	}

	figcaption {
		display: none;
	}


}
