/*
 * Button placement within text field
*/

$search-form-width: 60vw;

/*
 * search
 */

a,
input,
button {
  >svg {
    pointer-events: none;
  }
}

select {
  padding: 0;
  margin: 0;
}

.form--search {
  display: flex;
  position: relative;

  .form-layout {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    align-items: center;
  }

  //	width: $search-form-width; // found at top of molecules/_forms.scss

  input[type="search"],
  input[type="text"] {
    box-sizing: border-box;
    display: block;
    width: auto;
    //		height: 2.25em;
    margin-right: -1.625em;
    margin-bottom: 0;
    color: $color-text;
    padding: 0.5rem;

    flex: 1 1 auto;

    &:not([class*="placeholder"]) {
      @include placeholder {
        color: $color-shade;
      }
    }

    .menu--main & {
      border: 0;
    }
  }

  button {
    color: $anchor-color;
    padding: 2px;
    width: 2.25em;
    height: 2.25em;
    flex: 0 0 auto;

    transform: scale(1);

    transition: color $transition-time ease, transform 0.1s ease;

    &:hover,
    &:focus,
    &:active {
      color: darken($anchor-color, 5%);
    }

    &:hover,
    &:focus {
      transform: scale(1.25);
    }

    &:active {
      transform: scale(1.1);
    }
  }

  .search-close {
    font-size: 2.25em;
    line-height: 0.75;
  }
}

.form-inline {
  display: flex;
  flex-flow: row nowrap;

  align-items: center;

  margin-right: -0.5rem;
  margin-left: -0.5rem;

  >* {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .button {
    margin-bottom: 0;
  }
}

ul.inline-button {
  @include inline-list;

  justify-content: center;

  li {
    margin: 0 0.75rem 0.5rem;
    padding: 0;

    button {
      margin: 0;
      padding: 0;

      height: auto;
    }
  }
}

.inline-button {
  display: flex;
  flex-wrap: nowrap;

  justify-content: space-between;
  align-items: stretch;

  >* {
    display: block;
    box-sizing: border-box;
  }

  input,
  button {
    height: 2.25rem;
    padding: 0.5rem;
  }

  button,
  .button {
    margin: 0 0 0 1rem;
  }
}

.image-buttons,
ul.image-buttons {
  li {
    width: rem-calc(150);
    flex: 0 0 auto;
  }

  button,
  li button {
    width: 100%;
    border: $border-thin;
    border-color: $color-shade;
    border-radius: 0.25rem;
    padding: 0 0.5rem 0 0.25rem;

    box-shadow: 0 0 0 $color-shade-light;

    transition: all $transition-time ease;

    &:hover,
    &:focus {
      box-shadow: 0 1px 1px $color-shade-light;
    }
  }
}

.select-wrapper {
  background-color: $white;
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1rem;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'><path fill='#{$color-primary}' class='cls-1' d='M 3 8.5 C 1.7 10.8 1 13.5 1 16 C 1 18.6 1.7 21.2 3 23.5 C 4.3 25.8 6.2 27.7 8.5 29 C 10.8 30.3 13.5 31 16 31 C 18.6 31 21.2 30.3 23.5 29 C 25.8 27.7 27.7 25.8 29 23.5 C 30.3 21.2 31 18.5 31 16 C 31 13.4 30.3 10.8 29 8.5 C 27.7 6.2 25.8 4.3 23.5 3 C 21.2 1.7 18.5 1 16 1 C 13.4 1 10.8 1.7 8.5 3 C 6.2 4.3 4.3 6.2 3 8.5 Z M 17.2 6.065 C 17.9 6 18.5 6.6 18.5 7.3 L 18.5 17 L 22.2 13.4 C 22.7 12.9 23.4 12.9 23.9 13.4 L 25.5 15.1 C 26 15.8 26 16.6 25.5 17.1 L 16.7 25.9 C 16.1 26.4 15.3 26.4 14.7 25.9 L 6.4 17 C 5.9 16.4 5.9 15.6 6.4 15 L 8 13.5 C 8.5 13 9.3 13 9.8 13.5 L 13.5 17.2 L 13.5 7.4 C 13.5 6.7 14.1 6.1 14.8 6.1 L 17.2 6.065 Z'/></svg>");

  select {
    @include font-size("small");
    color: $color-primary;
    text-transform: uppercase;

    background: transparent;
    border: 0;
    border-radius: 0;
    line-height: 2.875;
    -webkit-appearance: none;
    -moz-appearance: none;
    // text-indent: 1px;
    text-overflow: "";
    padding-right: 2.5rem;
    padding-left: 0.75rem;

    &::-ms-expand {
      display: none;
    }
  }
}

// Ninja Forms
.ninja-forms-form-wrap {
  .btn {
    @include button(false, $color-primary, $color-secondary);
  }

  .nf-next-previous,
  .nf-field-element {
    li {
      &::before {
        display: none;
      }
    }
  }

  .nf-breadcrumbs {
    @include inline-list;

    li {

      a,
      &::after {
        margin-right: 0.5em;
      }

      &:not(:last-of-type)::after {
        content: "»";
      }
    }
  }
}

.list-radio-wrap,
.list-checkbox-wrap {
  .nf-field-element li {
    display: flex;

    input {
      height: 1.8em;
    }
  }
}

.label-right {
  .checkbox-wrap {
    .nf-field-label {
      flex: 0 1 calc(100% - 30px);
    }
  }
}