/* -------------------------------------------------------------- */
/*	Atoms - Text
/* _______________________________________________________________ */

body {

	@include font($font-primary, $size: 'medium', $scale: 1, $final-opts: (letter-spacing: normal), $fallback-opts: (letter-spacing: 0.51px));
}

pre {
	//scss-lint:disable ImportantRule
	font-size: 1rem !important;
}
/* Fonts
/* _______________________________________________________________ */

// font families
.font-primary {
	@include font-family($font-primary);
}

.font-secondary {
	@include font-family($font-secondary);
}

//.font-tertiary {
//	font-family: $font-tertiary;
//}

.font-primary-condensed {
	@include font-family($font-primary-condensed);
}

// font sizes
.font-size-smallest {
	font-size: $font-size-smallest;
}

.font-size-smaller {
	font-size: $font-size-smaller;
}

.font-size-small {
	font-size: $font-size-small;
}

.font-size-default {
	font-size: $font-size-default;
}

.font-size-large {
	font-size: $font-size-large;
}

.font-size-larger {
	font-size: $font-size-larger;
}

.font-size-largest {
	font-size: $font-size-largest;
}

p {
	// Style paragraph defaults with _foundation-settings.scss
}


abbr {
	border-bottom: 1px dotted $color-shade;
	cursor: help;
}

.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.letter-spaced {
	letter-spacing: rem-calc(2);
}

.no-wrap {
	white-space: nowrap;
}

blockquote {
	// Style blockquotes defaults in _foundation-settings.scss
}


hr {
	// Style the default HR with _foundation-settings.scss instead
}

.hr--invisible {
	border-bottom: 0;
}

.hr--dashed {
	@include dash-gradient($color-primary, 7, 8);
	border: 0;
	height: 3px;
}


/* Headings
/* _______________________________________________________________ */

// Most settings located in includes/_foundation-settings.scss
$final-opts: ();
$fallback-opts: ();

@mixin heading-page($size: 4.5rem, $color: $black) {
	font-size: $size;
	font-weight: bolder;

	color: $color;
	line-height: 1.125;
}

@mixin heading-left {
	text-transform: none;
	text-align: left;
}

@mixin heading-alpha($color: false, $weight: bold, $style: normal, $scale: 1.2, $transform: none) {
	@include font($font-secondary, $font-stack-serif, $size: 'h1', $scale: $scale, $final-opts: ( line-height: 1, margin-top: -0.16em, letter-spacing: normal ), $fallback-opts: ( letter-spacing: -0.6px ));

	@if ($color) {
		color: $color;
	}

	font-weight: $weight;
	font-style: $style;

	text-transform: $transform;

	.icon-wrapper {
		position: relative;
		top: -0.05em;
		width: 0.75em;
		height: 0.75em;
		line-height: 1em;
	}
}

@mixin heading-beta($color: false, $weight: bold, $style: normal, $scale: 1.11, $transform: uppercase) {

	@include font($font-secondary, $font-stack-serif, $size: 'h2', $scale: $scale, $final-opts: ( line-height: 1, margin-top: -0.16em ));

	@if ($color) {
		color: $color;
	} @else {
		color: inherit;
	}

	font-weight: $weight;
	font-style: $style;

	text-transform: $transform;
}

@mixin heading-gamma($color: false, $weight: bold, $style: normal, $scale: 1.19, $transform: none) {

	@include font($font-secondary, $font-stack-serif, $size: 'h3', $scale: $scale, $final-opts: ( line-height: 1, margin-top: -0.15em, letter-spacing: normal ), $fallback-opts: (letter-spacing : -0.7px ));

	@if ($color) {
		color: $color;
	}

	font-weight: $weight;
	font-style: $style;
	text-transform: $transform;

	.icon-wrapper {
		top: 0.05em;
	}
}

@mixin heading-delta($color: false, $weight: normal, $style: normal, $scale: 1.22, $transform: uppercase) {

	@include font($font-secondary, $font-stack-serif, $size: 'h4', $scale: 1.09, $final-opts: ( line-height: 1 ), $fallback-opts: ( ));

	@if ($color) {
		color: $color;
	}

	font-weight: $weight;
	font-style: $style;
	text-transform: $transform;
}

@mixin heading-epsilon($color: false, $weight: bold, $style: normal, $scale: 1.09, $transform: none) {

	@include font($font-primary, $font-stack-sans, $size: 'h5', $scale: $scale, $final-opts: ( line-height: 1.1 ), $fallback-opts: ( ));

	@if ($color) {
		color: $color;
	}

	font-weight: $weight;
	font-style: $style;
	text-transform: $transform;
}

@include headings(1) {
	@include heading-alpha;

}

@include headings(2) {
	@include heading-beta();

	&--alt {
		@include heading-beta($transform: none);
	}

	h1 + &,
	h1 + * > & {
		//scss-lint:disable ImportantRule
		margin-top: 0 !important;
	}

}

@include headings(3) {
	@include heading-gamma;

	h2 + &,
	h2 + * > & {
		//scss-lint:disable ImportantRule
		margin-top: 0 !important;
	}
}

@include headings(4) {
	@include heading-delta();
}

@include headings(5) {
	@include heading-epsilon();
}

.heading {
	line-height: $header-lineheight;
}

.heading--page {
	@include heading-page;
}

.heading--left {
	@include heading-left;

	/* &.heading--news {
		.subheader {
			color: $color-shade-darker;
			font-size: rem-calc(20);
			margin-top: rem-calc(20);
			text-transform: none !important;
			&:hover, &:active {
				color: $color-aqua;
			}
		}
	} */
}

.heading--news {

	h1 {
		line-height: 1.1 !important;
	}
	.column-portrait {
		//display: none;
		display: block;
		width: rem-calc(60);

		//width: 7rem + rem-calc(42);
		min-width: 0;
		padding: 0;
		margin-right: rem-calc(10);

		flex: 0 0 auto;
		/*
		@include breakpoint(760) {
			display: block;
			width: rem-calc(60);
		} */

		.image--circle, .image--circle > img  {
			border-width: 0;
		}
	}
	.subheader--featured-author {
		display: flex;
		align-items: left;
		margin-bottom: rem-calc(20) !important;
		@include breakpoint(medium) {
			align-items: center;
			margin-bottom: rem-calc(40) !important;
		}

		span {
			font-weight: bold;
		}

		a {
			color: $color-shade-darker;
			text-decoration: underline;
			&:hover, &:active {
				text-decoration: none;
			}
		}
	}

}

#news-featured-author-more {
	h2 {
		text-align: left !important;
		color: $color-shade-darker;
		font-size: rem-calc(24);
		text-transform: none;
		@include breakpoint(medium) {
			font-size: rem-calc(28);
		}
	}
}

.view-all {
	margin-bottom: rem-calc(20);
	text-align: right;
	a {
		color: $color-navy;
		font-family: $font-secondary;
		font-size: rem-calc(18);
		text-decoration: underline;
		&:hover, &:active {
			text-decoration: none;
		}
	}

	@include breakpoint(large) {
		margin-bottom: rem-calc(34);
	}
}

#news-featured-author-about {

	padding-top: rem-calc(46) !important;
	padding-bottom: rem-calc(56) !important;

	.medium-3 {
		padding-bottom: rem-calc(24);
		@include breakpoint(medium) {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
		}
	}

	.hgroup {
		margin-bottom: rem-calc(16);
	}

	h2 {
		color: $color-shade-darker;
		font-size: rem-calc(24);
		text-transform: none;
		@include breakpoint(medium) {
			font-size: rem-calc(28);
		}
	}

	p {
		color: $color-shade-darker;
		margin-bottom: 0;
	}

	a {
		color: $color-shade-darker;
		text-decoration: underline;
		&:hover, &:active {
			text-decoration: none;
		}
		.icon-wrapper {
			display: inline-block;
			margin-bottom: 0;
			top: 0.05em;
		}
	}

	.image--circle, .image--circle > img  {
		border-width: 0;
	}

	.image--circle {
		max-width: 100%;
		@include breakpoint(medium) {
			max-width: rem-calc(100);
		}
	}
}

.news--around-campus, .section--events-list {
	.heading--left {
		text-transform: uppercase;
	}
}

.information-for {
	div {
		h2:first-child {
			text-transform: uppercase;
		}
	}
}

//h5 {}

//h6 {}

.font-test-class {
	@include heading-delta;
}
