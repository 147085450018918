.homepage-bg-video {
	@include flex-video(16 by 9);
	width: 120%;
	margin-left: -10%;
}

header {
	h1.masthead__title {
		font-weight: bold !important;
	}

	&.masthead {
		position: relative;
		width: 100%;
		max-width: none;
		margin-top: -$space-page-section;
		margin-right: 0;
		margin-left: 0;

		overflow: hidden;

		figure {
			position: absolute;
			width: 100%;
			min-height: rem-calc(250);
			height: 100%;
			max-width: none;

			background-color: rgba($color-primary, 0.8);

			z-index: 0;

			@include breakpoint(medium) {
				position: relative;
				height: 0;
				min-height: 0;

				padding-bottom: 34%;
			}

			img {
				display: block;
				position: absolute;
				top: 0;
				left: 50%;

				height: 100%;
				min-width: none;
				max-width: none;
				width: auto;

				transform: translateX(-50%);

				filter: blur(2px);

				@include breakpoint(medium) {
					filter: none;
					position: relative;
					width: 100%;
					min-width: 100%;
					max-width: 100%;
					height: auto;

					left: 0;

					transform: none;
				}
			}
		}

		.masthead__content {
			position: relative;

			overflow: auto;

			z-index: 10;

			> * {
				text-align: center;
			}

			h1 {
				font-weight: 400;
				text-transform: uppercase;
			}

			.subheader {
				margin-top: rem-calc(8);
			}

			&.hide-circle {
				margin: 0;
				width: 100%;

				// Don't display any text other than the h1 if .hide-circle is in use.
				div {
					display: none;
				}
			}

			// breakpoint includes have to come at the end, despite the linter's
			// preferred declaration order
			//scss-lint:disable DeclarationOrder
			@include breakpoint(small down) {
				padding: 1rem;
				border-radius: 0;
			}

			@include breakpoint(medium) {
				@include font-size("large");
				position: absolute;

				width: rem-calc(600);
				height: rem-calc(599);

				&.text-circle {
					position: absolute;
					top: 50%;
					left: 50%;

					transform: translateX(-50%) translateY(-50%);

					&.hide-circle {
						background-color: transparent;
						max-width: 70%;

						h1 {
							@include colored-bg(rgba($color-primary, 0.8));
							font-weight: 600;
							margin-top: 0;
							margin-bottom: 0;
							padding: rem-calc(35 30 40 30);
						}
					}
				}
			}
		}

		&.masthead--home {
			figure {
				position: relative;
				height: auto;
				padding: 0;
				width: 100%;
				max-width: none;

				> img {
					position: relative;
					width: 100%;
					left: 0;
					transform: none;
					filter: none;
				}
			}

			.masthead__content {
				@include colored-bg(rgba($color-secondary, 0.8));
				position: absolute;

				top: auto;
				bottom: 0;

				margin-bottom: 0;

				padding: 2.5rem 2rem;

				width: 100%;

				color: $white;
				height: auto;

				.masthead__intro {
					max-width: rem-calc(800);
					margin-right: auto;
					margin-left: auto;
				}

				// breakpoint includes have to come at the end, despite the linter's
				// preferred declaration order
				//scss-lint:disable DeclarationOrder
				@include breakpoint(medium only) {
					margin-top: -5rem;
				}

				@include breakpoint(medium down) {
					background-color: $color-secondary;
					position: relative;
				}
			}

			.masthead__links {
				$masthead-icon-size: 5.75rem;
				position: relative;
				margin-top: -($masthead-icon-size / 2);

				padding-bottom: $space-page-section;

				.masthead__link {
					display: block;
					// flex-flow: column nowrap;
					// align-items: center;
					text-align: center;

					> * {
						display: block;
						width: 100%;
					}

					.icon-wrapper {
						margin-right: auto;
						margin-bottom: 1.25rem;
						margin-left: auto;
						width: $masthead-icon-size;
						height: $masthead-icon-size;

						border: solid 5px $white;
					}

					.link__title {
						@include font-size("h3");
						margin-bottom: 0.75rem;
					}

					.link__description {
						width: 100%;
					}

					a {
						@include anchor-colors($color-primary, $anchor-color);
					}
				}
			}
		}
	}
}

section {
	&.has-bg {
		padding-top: 5.25rem;
		padding-bottom: 5.25rem;

		&.bg-yellow {
			padding-top: 2.25rem !important;
			padding-bottom: 2.25rem !important;
		}

		+ .has-bg,
		+ .footer--page {
			margin-top: -1 * $space-page-section;
		}
	}

	&.section-header {
		margin-bottom: 0;
	}

	+ .section--featured-links {
		margin-top: -1 * $space-page-section;
	}

	&.full-width {
		margin-right: -1rem;
		margin-left: -1rem;
	}

	> .hgroup {
		:last-child {
			margin-bottom: -1.5rem;
		}
	}
}

.sidebar--main {
	$color-sidebar: $color-secondary;

	@include grid-column(24);

	// display: flex;
	//
	// flex-flow: row wrap;
	float: none;

	// justify-content: flex-start;

	padding-left: 0;

	> section {
		margin-left: 1.3125rem;

		margin-bottom: $space-page-section;

		padding: rem-calc(36);
		height: auto;

		flex: 1 0 auto;

		text-align: center;

		&.section--events-list {
			padding-top: 0;
		}

		.sidebar--homepage & {
			margin-bottom: 0;
		}

		.hgroup {
			h2 {
				margin-bottom: rem-calc(12);
			}
		}
	}

	// breakpoint includes have to come at the end, despite the linter's
	// preferred declaration order
	//scss-lint:disable DeclarationOrder
	@include breakpoint(large) {
		@include grid-column(8);
		flex-flow: column nowrap;

		> section {
			width: 100%;
			margin-bottom: rem-calc(60);
			margin-left: 0;
		}

		&.sidebar--homepage {
			> section {
				margin-bottom: 0;
			}
		}
	}

	.sidebar__nav {
		background-color: tint($color-sidebar, 25%);

		border: $border-thin;
		border-color: rgba($color-sidebar, 0.5);

		a {
			@include heading-epsilon;
			@include anchor-colors($color-primary, $white);
		}

		hr {
			margin-top: rem-calc(20);
			margin-bottom: rem-calc(20);
		}

		ul {
			margin: 0;
			padding: 0;

			> li {
				margin: 0;
				padding: 0;
				text-indent: 0;

				&::before {
					display: none;
				}

				a {
					display: block;
				}
			}
		}
	}

	.sidebar__contact,
	.sidebar__calendars {
		padding-bottom: rem-calc(36);
		border: $border-med;
		border-color: $color-sidebar;

		margin-top: 0;

		@include breakpoint(large) {
			margin-top: 1rem;
		}

		.hgroup {
			margin-bottom: rem-calc(24);
		}

		hr {
			margin-top: rem-calc(24);
			margin-bottom: rem-calc(24);
		}
	}

	.sidebar__calendars {
		.card__title {
			color: $color-text;
		}
	}

	.sidebar__testimonial,
	.sidebar__callout {
		flex: 1 0 75%;

		padding: 0;

		.card {
			padding: 0;
		}
	}

	.sidebar__testimonial {
		.card {
			display: flex;
			flex-flow: row wrap;

			align-items: flex-start;

			.image-circle-wrapper {
				max-width: 12.5rem;
				margin-bottom: 0;
			}

			// breakpoint includes have to come at the end, despite the linter's
			// preferred declaration order
			//scss-lint:disable DeclarationOrder
			@include breakpoint(medium only) {
				.image-circle-wrapper {
					padding-right: rem-calc(36);
					padding-left: rem-calc(36);
					margin: 0;
					max-width: rem-calc(320);
					flex: 0 1 30%;
				}

				.hgroup {
					text-align: left;
				}

				.icon-wrapper {
					.quote-double {
						float: left;
						margin-right: 1rem;
					}
				}

				.quote {
					text-align: left;
				}

				.text-content {
					flex: 0 1 70%;
					padding-top: 3%;
				}
			}
		}
	}

	.sidebar__callout {
		.callout__image {
			margin-bottom: rem-calc(24);
		}

		.callout__title {
			color: $color-primary;
		}

		.button {
			@include button-style($color-tertiary, $color-primary);
			margin-bottom: 0;
		}

		@include breakpoint(medium only) {
			display: flex;
			flex-flow: row nowrap;

			.callout__image {
				width: 150%;
				flex: 0 1 auto;
				margin-right: 1.3125rem;
			}

			.text-content {
				flex: 1 1 auto;
				text-align: left;
			}
		}
	}

	.icon--circle {
		background-color: $color-sidebar;
	}
}

.hr-behind {
	position: relative;

	> * {
		z-index: 2;
	}

	hr {
		//sass-lint:disable no-important
		position: absolute;
		top: (4.375rem / 2) - 0.25rem;
		width: 100% !important;
		max-width: none;
		z-index: 1;
	}

	&.has-bg {
		hr {
			top: 5.25rem + (4.375rem / 2) - 0.25rem;
		}
	}
}

.content-wrapper,
.page--narrow {
	> .row {
		:last-child {
			:not(.has-bg) {
				min-height: 1rem;
			}
		}
	}

	> .hgroup,
	> h1 {
		margin-bottom: $space-page-section;
	}

	> section {
		padding-top: 1rem;
		padding-bottom: 1rem;

		margin-bottom: $space-page-section;

		&.has-bg {
			padding-top: 5.25rem;
			padding-bottom: 5.25rem;

			&:last-child {
				margin-bottom: 0;
			}

			+ .has-bg {
				margin-top: -$space-page-section;
			}
		}
	}
}

.content--main {
	> section {
		@include clearfix;
		margin-bottom: 2.5rem;

		// sass-lint:disable force-element-nesting
		> .row.row.row {
			&.column,
			> .column {
				padding-right: 1.3125rem;
				padding-left: 1.3125rem;
			}
		}

		&.section-header {
			margin-bottom: 0;
		}
	}

	// breakpoint includes have to come at the end, despite the linter's
	// preferred declaration order
	//scss-lint:disable DeclarationOrder
	@include breakpoint($global-width) {
		&.column {
			padding: 0;
		}
	}
}

.content--general {
	section {
		margin-bottom: 2.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.aside {
	@include breakpoint(medium) {
		@include grid-column(12);
		float: right;
	}
}

//sass-lint:disable no-ids
#curriculum-admissions {
	.hgroup {
		.icon-wrapper {
			background-color: $color-tertiary;
		}
	}
}

#faculty-slideshow {
	text-align: center;
}

.row {
	&.center-vertical {
		flex-flow: row wrap;
	}
}

.profile-primary {
	.content-wrapper > &,
	.page--narrow > & {
		padding-bottom: 0;
	}

	.hgroup {
		text-align: left;

		.subheader {
			&:not([class*="text-"]) {
				@include heading-epsilon($color: $color-shade-dark);
			}
		}
	}

	.profile__portrait {
		margin-top: 0;
		margin-bottom: 1rem;
		width: 100%;

		> div {
			width: rem-calc(165);
			height: rem-calc(165);
			// min-width: rem-calc(150px);
			// max-width: rem-calc(200);

			margin-right: auto;
			margin-left: auto;
		}

		.image--circle {
			padding-bottom: 0;
		}

		// breakpoint includes have to come at the end, despite the linter's
		// preferred declaration order
		//scss-lint:disable DeclarationOrder
		@include breakpoint(medium) {
			float: right;
			padding-left: map-get($grid-column-gutter, small) / 2;
			width: rem-calc(220);

			margin-right: 0;
			margin-bottom: 1rem;
			margin-left: auto;

			> div {
				width: 100%;
				max-width: none;
			}

			.image--circle {
				padding-bottom: calc(100% - 4px);
			}
		}
	}
}

.contact-info--list {
	display: flex;
	flex-flow: row wrap;

	.contact-header & {
		justify-content: space-between;
	}

	.list-column {
		padding-right: map-get($grid-column-gutter, small);
		flex: 1 1 auto;
		width: 100%;

		&:last-of-type {
			padding-right: 0;
		}
	}

	// breakpoint includes have to come at the end, despite the linter's
	// preferred declaration order
	//scss-lint:disable DeclarationOrder
	@include breakpoint(medium) {
		.list-column {
			max-width: 50%;
			width: rem-calc(240);
		}

		.list-column > * {
			white-space: nowrap;
			margin-bottom: rem-calc(20);
		}
	}
}

.information-for {
	background-color: $color-quaternary;

	h2 {
		text-transform: none;
		margin-bottom: rem-calc(30);
	}
}

$color-footer: $color-quaternary;

.footer--page {
	background-color: $color-footer;

	padding-top: rem-calc(30);

	> .row {
		padding-right: 1.3125rem;
		padding-left: 1.3125rem;
	}

	.footer--page__heading {
		line-height: 0.9em;
		margin-bottom: 0.5em;
		padding-right: rem-calc(32);
		padding-left: rem-calc(32);

		text-indent: rem-calc(-30);

		a {
			text-indent: 0;
		}

		.icon-wrapper {
			&.home {
				left: -0.1em;
			}
		}
	}

	a {
		@include anchor-colors($color-primary, $anchor-color);

		&.link--underlined {
			display: inline-block;
			border-bottom: solid 2px $color-primary;

			@include hover {
				border-bottom-color: $anchor-color;
			}
		}
	}

	.column-header {
		@include font-size("medium");
		font-weight: bold;
		margin-bottom: 0;
	}

	ul {
		@include strip-list;

		li {
			margin-top: 0.625rem;
		}
	}

	.footer--page__lists {
		$color-resources-column: adjust-hue(shade($color-footer, 4), -3);
		display: flex;
		flex-flow: row-reverse wrap;

		.footer__resources-column,
		.link-column {
			padding-top: rem-calc(24);
			padding-bottom: rem-calc(36);

			padding-right: rem-calc(32);
			padding-left: rem-calc(32);
		}

		.footer__resources-column {
			width: rem-calc(230);
			flex: 1 0 auto;

			background-color: $color-resources-column;
		}

		.footer__link-columns {
			display: flex;

			flex-flow: row wrap;

			width: rem-calc(240);

			// min-width: rem-calc(240);

			flex: 4 1 auto;

			> .column {
				width: rem-calc(230);
				flex: 0 0 auto;
			}
		}
	}
}

.social-stream {
	.ff-layout-grid {
		//scss-lint:disable ImportantRule
		padding-top: 0;
		min-height: 0 !important;
	}

	.ff-stream-wrapper {
		margin-top: 0;
		margin-right: 0;
		margin-left: 0;
	}
}

.section--news-events {
	.info-block__photo {
		margin-bottom: rem-calc(42);
	}
}

.feature-bottom {
	position: relative;

	> div {
		position: relative;
		z-index: 1;
	}

	.hero-image {
		z-index: 0;
		position: relative;

		margin-top: -3rem;
	}
}

.quotes-slideshow {
	.hgroup,
	.intro-text {
		text-align: center;
		width: 100%;
	}

	.hgroup,
	.intro-text,
	.text-content {
		width: 100%;
	}

	.button {
		margin-right: auto;
		margin-left: auto;
	}

	.tab-group .tabs-panel > & {
		margin-bottom: -(2rem + $space-page-section);
	}

	//scss-lint:disable DeclarationOrder
	@include breakpoint(medium) {
		> .row {
			> .column {
				width: calc(50% - 1px);
			}
		}
	}
}
