.news-preview {

	margin-bottom: 2.125rem;

	.article__date {
		@include font($font: $font-secondary, $fallback: $font-stack-serif, $scale: 1.375);
		color: $anchor-color;
	}

	.article__title {
		@include heading-gamma($scale: 1);

		a {
			@include anchor-colors($color-primary, $anchor-color);
		}
	}

}

.event-list {
	@include strip-list;

	.event-item {
		margin-bottom: rem-calc(36);
	}
}

.event-item {
	display: flex;
	flex-flow: row nowrap;

	.event__date--large {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;

		margin-right: 1rem;

		color: $color-quinary;

		> * {
			display: block;
		}


		.event__month {
			@include heading-epsilon($color: inherit, $scale: 0.94117);
			text-transform: uppercase;
			margin-bottom: 0.25rem;
		}

		.event__day {
			@include heading-beta($color: inherit);
		}
	}

	.event__details {
		font-weight: bold;

		text-align: left;

		a {
			@include anchor-colors($color-primary, $anchor-color);
		}

		.event__date {
			@include heading-epsilon($color: $anchor-color, $scale: 0.94117);
		}

	}
}

.featured-links {
	@include strip-list;

	li {
		@include heading-gamma($scale: 0.9);
		margin-bottom: 0.5em;

		.icon-wrapper {
			top: 0.15em;
		}

		a {
			@include anchor-colors($color-coral, $color-orange);
		}
	}
}

.list-interface--top {
	margin-bottom: 2.125rem;
}

.list-interface--bottom {
	margin-top: 2.125rem;
}

.search-interface {
	.results-count {
		text-transform: none;
	}

	form {
		margin-bottom: 2.215rem;
	}
}

.tab-group .link-columns.program-list-wrapper {
	flex-flow: row wrap;
	padding-top: 2.25rem;
}

.program-list {
	width: 320px;
	padding: 0 1rem 0 0;

	flex: 1 0 auto;

	li {
		display: flex;
	}
}

.program-list__icons {
	display: flex;
	flex-flow: column wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin-right: 0.5rem;

	width: 4rem;
	height: 1.75rem;

	flex: 0 0 auto;

	> * {
		font-size: 1rem;
		margin-top: 0.2rem;
		margin-right: 0.5rem;

		line-height: 1;

		&.program-icon__circle {
			align-self: flex-end;
		}
	}
}

.tab-group .link-columns.list-centered {
	justify-content: center;
}
