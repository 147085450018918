/* -------------------------------------------------------------- */
/*    Variables
/* -------------------------------------------------------------- */
/* Colors
/* remember to turn off any unused swatches in atoms>global>colors.mustache
/* _______________________________________________________________ */
$color-undefined: #e31ace;
// Brand Color Palette: primaries
$white: #fff;
$black: #000;
$color-green: #8fc73e;
$color-orange: #e78d1d;
$color-coral: #ff5349;
$color-teal: #61c8cd;
$color-yellow: #ffd200;
$color-navy: #002b5c;
$color-primary: $color-navy;
$color-secondary: $color-teal;
$color-tertiary: $color-green;
$color-quaternary: $color-yellow;
$color-quinary: $color-coral;
$color-senary: $color-orange;
//$color-septenary: $color-undefined;
//$color-octonary: $color-undefined;
//: $color-undefined;
//$color-denary: $color-undefined;
// Brand Color Palette: accents
$color-accent-primary: $color-navy;
$color-accent-secondary: $color-teal;
$color-accent-tertiary: $color-green;
$color-accent-quaternary: $color-yellow;
$color-accent-quinary: $color-coral;
$color-accent-senary: $color-orange;
//$color-accent-septenary: $color-undefined;
//$color-accent-octonary: $color-undefined;
//$color-accent-nonary: $color-undefined;
//$color-accent-denary: $color-undefined;
// Grays / Shades / Support Colors
$color-shade-lightest: #fafafa;
// these grays are from longwood. we can keep, make more generic, or use color-undefined. I'll decide later.
$color-shade-lighter: #f3f3f3;
$color-shade-light: #c7ced1;
$color-shade: #8f9ea3;
$color-shade-dark: #5c6b70;
$color-shade-darker: #2e3538;
$color-shade-darkest: $black;
// Utility Colors
$color-text: $color-shade-darker;
$color-anchor: darken($color-secondary, 15%);
$color-text-shade: $color-shade-dark;
$color-text-light: #fff;
$color-bg: $white;
$color-border: $color-undefined;
$opacity-overlay: 0.8;
$color-overlay: rgba(#000, $opacity-overlay);
// add more here as needed
// Maps color values
//$colors-normal: ( primary: $color-primary, secondary: $color-secondary, tertiary: $color-tertiary, quaternary: $color-quaternary, quinary: $color-quinary, senary: $color-senary, septenary: $color-septenary, octonary: $color-octonary, nonary: $color-nonary, denary: $color-denary );
$colors-normal: (
	primary: $color-primary,
	secondary: $color-secondary,
	tertiary: $color-tertiary,
	quaternary: $color-quaternary,
	quinary: $color-quinary,
	senary: $color-senary,
);
//$colors-accents: ( primary: $color-accent-primary, secondary: $color-accent-secondary, tertiary: $color-accent-tertiary, quaternary: $color-accent-quaternary, quinary: $color-accent-quinary, senary: $color-accent-senary, septenary: $color-accent-septenary, octonary: $color-accent-octonary, nonary: $color-accent-nonary, denary: $color-accent-denary );
$colors-accents: (
	primary: $color-accent-primary,
	secondary: $color-accent-secondary,
	tertiary: $color-accent-tertiary,
	quaternary: $color-accent-quaternary,
	quinary: $color-accent-quinary,
	senary: $color-accent-senary,
);
$colors-shades: (
	shade: $color-shade,
	dark: $color-shade-dark,
	darker: $color-shade-darker,
	darkest: $color-shade-darkest,
	light: $color-shade-light,
	lighter: $color-shade-lighter,
	lightest: $color-shade-lightest,
);
// leaving these originals for now until I have time to remove the vars from the rest of the sass - jcs
//Colors
$gray: $color-shade;
$light-gray: $color-shade-light;
$dark-gray: $color-shade-dark;
$white: #fff;
$black: #000;
$color-brand: $color-primary;
$color-brand-darken: darken($color-brand, 0.25);
$color-dark-overlay: rgba(0, 0, 0, 0.64);
$color-light-background: $color-shade-lightest;
$color-light-border: $color-shade-light;
$color-brand-complement: $color-tertiary;
$color-brand-complement-light: $color-quaternary;

.bg-dark {
	background-color: $black;
	color: $white;
}

// .bg-white {
// 	background-color: $white;
// }

// .bg-transparent {
// 	background-color: transparent;
// 	color: $white;
// }

.bg-light {
	background-color: $color-light-background;
}

.bg-brand {
	background-color: $color-brand;
	color: $white;
}

.bg-gray {
	background-color: $color-shade;
	color: $black;
}

.bg-complement {
	background-color: $color-brand-complement;
	color: $white;
}

.bg-yellow {
	background-color: $color-yellow;
	color: $black;
}

.bg-alpha {
	$alphac: 0.8;

	&.bg-dark {
		background-color: rgba($black, $alphac);
		color: $white;
	}

	&.bg-white {
		background-color: rgba($white, $alphac);
	}

	&.bg-light {
		background-color: rgba($color-light-background, $alphac);
	}

	&.bg-brand {
		background-color: rgba($color-brand, $alphac);
		color: $white;
	}

	&.bg-gray {
		background-color: rgba($gray, $alphac);
		color: $black;
	}

	&.bg-complement {
		background-color: rgba($color-brand-complement, $alphac);
		color: $white;
	}
}
/* Typography
/* _______________________________________________________________ */
// Default font stacks
$font-stack-sans: "Helvetica", "Arial", sans-serif;
$font-stack-serif: "Georgia", serif;
$font-stack-slab: "Rockwell", "Georgia", serif;
/*iOS*/
// (make sure to use font-stretch: condensed if you are using
// the condensed stack)
$font-stack-condensed: "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", /*MacOSX*/ HelveticaNeue-CondensedBold,
	/*Ubuntu*/ "Ubuntu Condensed", "Liberation Sans Narrow", /*Windows*/ "Franklin Gothic Demi Cond", "Arial Narrow",
	/*Android*/ sans-serif-condensed, /*Fallback*/ Arial, "Trebuchet MS", "Lucida Grande", Tahoma, Verdana, sans-serif;
// Setup font families
$font-primary: "Muli", $font-stack-sans;
$font-primary-condensed: $font-primary, $font-stack-condensed;
$font-secondary: "Oswald", $font-stack-serif;
// Font sizing
$base-font-size: 16px;
$font-size-smallest: 0.65em;
$font-size-smaller: 0.75em;
$font-size-small: 0.875em;
$font-size-default: 1em;
$font-size-large: 1.25em;
$font-size-larger: 1.5em;
$font-size-largest: 2em;
$font-sizes: (
	"small": (
		"xsmall": 11,
		"small": 14,
		"medium": 16,
		"large": 16,
		"xlarge": 18,
	),
	"medium": (
		"xsmall": 11,
		"small": 14,
		"medium": 16,
		"large": 18,
		"xlarge": 22,
	),
);
/* Paths
/* _______________________________________________________________ */
$image-url-path: "../images/";
//Layout
$max-width: 75rem; // Rem equivalent of 1200px
$masthead-height: 26rem;
//Defaults
$space: 1rem;
$space-and-half: $space * 1.5;
$space-double: $space * 2;
$space-quad: $space * 4;
$space-half: $space / 2;
$space-page-section: 2.125rem;
$pad: 1.25rem;
$pad-and-half: $pad * 1.5;
$pad-double: $pad * 2;
$pad-half: $pad / 2;
$pad-quarter: $pad / 4;
$pullquote-margin: 0;
$negative-bleed: -9999px;
$ornament-size: 3.625rem;
$ornament-size: 1em;
// Used to calculate a few values for bullet positioning
$link-list-font-size: 1.25rem;
$list-bullet-font-size: $link-list-font-size;
$list-bullet-spacing: 4;
//Borders
$border-opacity: 1;
$border-color: $color-shade-light;
$border-thin: solid 1px rgba($border-color, $border-opacity);
$border-med: solid 2px rgba($border-color, $border-opacity);
$border-thick: solid 3px rgba($border-color, $border-opacity);
$border-xthick: solid 6px rgba($border-color, $border-opacity);
$border-thin-dark: solid 1px $border-color;
$border-med-dark: solid 2px $border-color;
$border-thick-dark: solid 3px $border-color;
$border-xthick-dark: solid 6px $border-color;
// animations
$transition-time: 0.3s;
$transition-time-slow: 0.8s;
// breadcrumbs
$crumb-border-size: 0;
$crumb-border-style: none;
$crumb-radius: 0;
//
