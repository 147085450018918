@import "theme-header";
@tailwind base;

@import "includes/variables";
@import "includes/autocomplete";
@import "includes/math";
@import "includes/mixins";
@import "includes/slick-settings";

/*
* Foundation
*/
@import "libraries/motion-ui/motion-ui";
@import "includes/foundation-settings";
@import "libraries/foundation-sites/scss/util/mixins";
@import "libraries/foundation-sites/scss/foundation";

@include foundation-global-styles;
@include foundation-grid; // mutually exclusive with foundation-flex-grid
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-dropdown-menu;
@include foundation-visibility-classes;
@include foundation-accordion;
@include foundation-close-button;
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-tabs;
@include foundation-dropdown-menu;

/*
* Icons
* don't directly reference bower-supplied iconsets in your objects, instead put
* includes and mapping in the below icon settings file
*/
@import "includes/icons";

/*
 * Patternlab styles
 */

// @import "global/testing";
@import "global/pl-overrides";
@import "global/accessibility";
@import "global/backgrounds";
@import "global/borders";
@import "global/layout";
@import "global/fonts";
@import "global/text";
@import "atoms/text";
@import "atoms/links";
@import "atoms/tables";
@import "atoms/lists";

@import "atoms/images";
@import "atoms/icons";
@import "atoms/buttons";
@import "atoms/social-media-icons";
//
@import "molecules/blocks";
@import "molecules/components";
@import "molecules/forms";
@import "molecules/media";
@import "molecules/messaging";
@import "molecules/lists-feeds";
@import "molecules/navigation";
@import "molecules/text";
// //
@import "organisms/header-footer";
@import "organisms/sections";
@import "organisms/collections";
@import "organisms/carousel";

// Template, page styles could go below here
// but if you have any you might want to rethink
// your approach.

@import "includes/testing";

// Library page widget

[id="widget-aone"] {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  width: 180px;
  max-width: 400px;
  height: 110px;
  background-image: url("https://assets.cengage.com/gale/icons/aone-widget.jpg");
  background-repeat: no-repeat;
}

[id="widget-click-aone"] {
  position: absolute;
  top: 0;
  width: 100%;
  height: 68px;
}

[id="widget-search-aone"] {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
  padding: 0 5px 5px 5px;

  form {
    margin: 0;
    padding: 0;
  }

  [type="text"] {
    float: left;
    box-sizing: border-box;
    border: 1px solid #999;
    border-right: 0;
    font-size: 13px;
    color: #333;
    padding: 3px;
    height: 22px;
    width: 150px;
    line-height: 22px;
  }

  button {
    float: left;
    border: 1px solid #999;
    border-left: 0;
    width: 20px;
    height: 22px;
    background: #fff url("https://support.gale.com/assets/widget-search.png") no-repeat;
  }
}


[id="widget-title-aone"] {
  font-size: 13px;
  color: #fff;
  padding: 10px 0 0 10px;
  margin-top: 0;
}



[id="library-search-widgets"] {
  display: flex;
  justify-content: space-around;

  [id="worldcatsearch"] {
    flex: 1;
    padding-left: 2rem;
  }
}

@responsive {
  .sr-only {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  }
}
