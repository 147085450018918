/* Copied from SOLR plugin */
.ac_results {
	border: 1px solid gray;
	background-color: white;
	padding: 0;
	margin: 0;
	list-style: none;
	position: absolute;
	z-index: 10000;
	display: none;
}

.ac_results li {
	padding: 2px 5px;
	white-space: nowrap;
	color: #101010;
	text-align: left;
}

.ac_over {
	cursor: pointer;
	background-color: #F0F0B8;
}

.ac_match {
	text-decoration: underline;
	color: black;
}
