/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/

@mixin button-hollow-style($color: $primary-color, $color-hover: $white, $hover-bg: false, $border-width: 1px) {
	@if ($hover-bg == false) {
		$hover-bg: $color;
	}

	@include anchor-colors($color, $color-hover);

	border: $border-width solid $color;
	color: $color;

	@include hover {
		border-color: $color-hover;
		background-color: $hover-bg;
	}
}

@mixin hollow-version($color, $hover-color: false, $hover-bg: false) {
	&.button--hollow {
		@include button-hollow;
		@include button-hollow-style($color, $hover-color, $border-width: 2px);

		transition: all 0.25s ease-out;
	}
}

.button {
	@include button-style($color-primary, $color-secondary);
	@include hollow-version($color-primary, $white);

	display: inline-block;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 0.125em;
	// font-weight: 400;

	.bg-primary & {
		@include button(false, $white, $white, $color-secondary);

		@include anchor-colors($color-primary, $color-secondary);

		@include hollow-version($white, $color-primary, $white);
	}

	.bg-secondary & {
		@include button-style($color-primary, lighten($color-primary, 10%));

		@include anchor-colors($white);

		@include hollow-version($white, $color-primary, $white);
	}

	//	// a lot of button settings should be set in _foundation_settings: sizes, padding, border radius, etc.

	&--icon {
		border-radius: 0;

		.icon-wrapper:not(.icon-link) {
			height: 1em;
			width: 1em;
			border-radius: 0;
		}
	}

	&--large {
		padding: (nth($button-padding, 1) * 1.5) (nth($button-padding, 2) * 1.5);

		&.button--align-text {
			margin-top: -1 * nth($button-padding, 1);
		}
	}

	&--white,
	.bg-primary &--white {
		&.button {
			@include button(false, $white, $white, $color-secondary);

			@include anchor-colors($color-secondary, $color-primary);

			@include hollow-version($white, $anchor-color, $white);

			@include anchor-colors($color-primary, $color-secondary);
		}
	}

	&--secondary,
	.bg-primary &--secondary,
	.bg-secondary &--secondary {
		@include button-style($color-secondary, $color-primary);

		@include hollow-version($color-secondary, $white);

		@include anchor-colors($color-primary);
	}

	&--call {
		@include button-style($color-secondary, $color-accent-primary);

		@include hollow-version($color-secondary, $color-accent-primary);
	}

	&--dark {
		@include button(false, $black, $color-shade-dark, auto);

		@include hollow-version($black, $color-primary);
	}

	&--arrow,
	.bg-primary &--arrow {
		position: relative;
		overflow: hidden;

		&.button {
			padding-right: nth($button-padding, 2) + $button-icon-width;
			padding-left: nth($button-padding, 2) + $button-icon-width;
		}

		&::before,
		&::after {
			display: block;
			width: $button-icon-width;

			text-align: right;
			font-family: $icon-font;
		}

		&::before {
			position: absolute;
			right: nth($button-padding, 2);

			line-height: 1;

			content: $fa-var-arrow-right;

			speak: none;
		}
	}

	&--animated {
		&::before {
			transition: right $transition-time ease;
		}

		&.button--arrow {
			@include hover {
				&::before {
					right: nth($button-padding, 2);
				}
			}

			&::before {
				right: -1 * $button-icon-width;
			}
		}
	}

	&--prev,
	&--next,
	&--up,
	&--down {
		margin-bottom: 0;
		padding: 0;
		width: 3rem;
		height: 3rem;

		//scss-lint:disable ImportantRule
		font-size: 0 !important;

		&::before {
			@extend .fa;

			line-height: 3rem;
			font-size: 1rem;
		}
	}

	&--prev::before {
		content: $icon-chevron-left;
	}

	&--next::before {
		content: $icon-chevron-right;
	}

	&--up::before {
		content: $icon-chevron-up;
	}

	&--down::before {
		content: $icon-chevron-down;
	}

	// in a list? lose the margin
	li & {
		margin-bottom: rem-calc(2);
	}
}
