/* Icon customization */

.icon-wrapper {
	&.icon--circle {
		width: 4.375rem;
		height: 4.375rem;
		color: $white;
		background-color: $color-quinary;

		border-radius: 50%;

		svg {
			$svgscale: 50%;
			width: $svgscale;
			height: $svgscale;
			margin-left: (100% - $svgscale) / 2;
			margin-top: (100% - $svgscale) / 2;
		}
	}

	.hgroup & {
		margin-bottom: rem-calc(20);
	}
}

.hgroup span.icon-wrapper {
	margin-bottom: 0;
}

// *[class^="heading--"],
// *[class*=" heading--"] {
// 	span.icon-wrapper {
// 		.home {
//
// 		}
// 	}
// }

*[class*="program-icon"] {
	display: flex;

	justify-content: center;
	align-items: center;

	width: 1.5em;
	height: 1.5em;
	color: $white;
}

.program-icon__circle {
	border-radius: 50%;

	> * {
		position: relative;
		bottom: 0.1em;
	}
}

.program-icon__secondary {
	background-color: $color-secondary;
}

.program-icon__senary {
	background-color: $color-senary;
}
