/* -------------------------------------------------------------- */
/*    Atoms - Lists
/* -------------------------------------------------------------- */

// MIXINS
/* Inline UL Lists
 /* _______________________________________________________________ */

@mixin inline-list($padding: 0 0.5rem, $flush: false) {
	list-style: none;
	display: flex;
	flex-flow: row wrap;

	@if ($flush == left) or ($flush == both) {
		@if length($padding) == 1 {
			margin-left: -1 * $padding;
		} @else if length($padding) < 4 {
			margin-left: -1 * nth($padding, 2);
		} @else if length($padding) == 4 {
			margin-left: -1 * nth($padding, 4);
		} @else {
			@error "Invalid value for $padding: '#{$padding}'";
		}
	}

	@if ($flush == right) or ($flush == both) {
		@if length($padding) == 1 {
			margin-right: -1 * $padding;
		} @else if length($padding) < 4 {
			margin-right: -1 * nth($padding, 2);
		} @else if length($padding) == 4 {
			margin-right: -1 * nth($padding, 4);
		} @else {
			@error "Invalid value for $padding: '#{$padding}'";
		}
	}


	li {
		display: block;
		text-indent: 0;

		margin: 0;

		padding: $padding;

		&::before {
			display: none;
		}

	}
}

@mixin list-dividers($color: currentColor, $thickness: 1px, $height: 1em) {
	li {
		position: relative;

		&::after {
			display: block;
			position: absolute;

			top: 50%;
			right: 0;

			transform: translateY(-50%);

			width: $thickness;
			height: $height;
			background-color: $color;
			content: '';
		}

		&:last-of-type {
			&::after {
				content: none;
			}
		}
	}
}

/* Definition Lists
 /* _______________________________________________________________ */

dl {
	margin: 0;

	> dt {
		margin: 0;

		&.no-definition {
			margin-bottom: 0.75rem;
		}
	}
}

dd {
	margin-left: 0;
}


/* Unordered, Ordered (set defaults)
 /* _______________________________________________________________ */
$number-width: 1rem;
$number-margin: 0.5rem;

main {
	ol,
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		counter-reset: brand-counter;
		margin-bottom: rem-calc(20);
	
		> li {
			text-indent: -1 * ($number-width + $number-margin);
			margin-left: $number-width;
	
			&::before {
				display: inline-block;
				color: $color-shade;
			}
		}
	}
	
	ul {
		// Used to calculate a few values for bullet positioning
		$bullet-font-size: 0.5em;
		$bullet-spacing: 0.5rem;
	
		/* Loose UL Lists
		 /* _______________________________________________________________ */
	
		&.loose-list {
			> li {
				margin-bottom: rem-calc(10);
			}
		}
	
		&.inline {
			@include inline-list($flush: both);
	
			&.dividers {
				@include list-dividers;
			}
	
			&.spaced-more {
				li {
					margin-right: rem-calc(40);
	
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	
		> li {
	//		margin-left: 0.5em;
			margin-left: calc( ( #{$bullet-font-size} + #{$bullet-spacing} ) );
			text-indent: calc( ( #{$bullet-font-size} + #{$bullet-spacing} ) * -1 );
	//		padding: $bullet-font-size;
	//		text-indent: 0;
	
	
			&::before {
				// use the fontawesome small dot,
				// which is less ugly and intrusive than the html default bullet
				content: $icon-bullet;
				font-family: $icon-font;
				font-size: $bullet-font-size;
				vertical-align: ($bullet-font-size / 2);
				text-indent: 0;
				width: 1em;
	//			margin-left: calc( ( 1em + #{$bullet-spacing} ) ) ;
				margin-left: 0;
				margin-right: $bullet-spacing;
			}
		}
	}
	
	ol {
		> li {
			counter-increment: brand-counter;
	
			&::before {
				margin-left: 0.75rem;
				margin-right: $number-margin;
				content: counter(brand-counter) '. ';
	
				text-align: right;
			}
		}
	}

}


/* Strip Unordered List
 /* to use a list for layout instead of editorial/text/content
 /* _______________________________________________________________ */

.strip-list {
	margin: 0;

	> li {
		text-indent: 0;
		margin-left: 0;

		&::before {
			display: none;
		}
	}
}

ul[class*="block-grid"] {
	@extend .strip-list;
}

.list--wrapped {

	display: block;
	column-count: 2;

	li {
		display: block;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}


	&.across {
		display: flex;
		flex-flow: row wrap;
		flex: 1 0 auto;

		li {
			display: block;
			width: 50%;
			margin-bottom: $space;

			&:nth-child(even) {
				padding-left: $pad;
			}
		}
	}

}
