.aside {
	margin-bottom: 1rem;

	> .callout {
		//		display: flex;
		@include shadow($opacity: 0.125, $width: 90%, $height: 0.5rem, $radius: 25%);

		margin-top: rem-calc(-14);
		padding: 2rem;

		background-color: $color-quaternary;

		border: solid rem-calc(14) $white;

		text-align: center;

		.icon-wrapper,
		.callout__text,
		h1,
		h2,
		h3,
		h4 {
			margin-bottom: 1rem;
		}

		.icon-wrapper {
			margin-right: auto;
			margin-left: auto;
		}

		> .button {
			margin-right: 0;
		}

		> .button:last-child {
			margin-bottom: 0;
		}
	}
}

.card {
	@include colored-bg($white);
	padding: $space-page-section;

	#experience & {
		width: 100%;
	}

	&,
	.bg-secondary a & {
		font-weight: normal;
	}

	.bg-secondary & {
		a {
			@include anchor-colors($anchor-color, $color-primary);
		}

		h1 a,
		h2 a,
		h3 a,
		h4 a {
			@include anchor-colors(inherit, $anchor-color);
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		color: $color-primary;
	}

	.block-row:not(.has-photo-bg) > & {
		padding-top: 0;
		padding-bottom: 0;
	}

	.hgroup {
		margin-bottom: 0.25rem;

		.card__title {
			color: $color-primary;
		}
	}

	&.card--translucent {
		@include colored-bg(rgba($white, 0.9));
	}

	&.card--icon {
		position: relative;
		margin-top: rem-calc(34);
		padding-top: rem-calc(68);

		.icon-wrapper {
			position: absolute;
			top: 0;
			left: 50%;

			transform: translate3d(-50%, -50%, 0);
		}
	}

	p:last-child,
	> *:last-child p:last-child {
		margin-bottom: 0;
	}
}

.card--quote {
	display: flex;

	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;

	.hgroup {
		margin-bottom: 0.875rem;

		a {
			color: inherit;

			@include hover {
				color: $anchor-color;
			}
		}
	}

	.subheader {
		@include font(
			$font-primary,
			$font-stack-sans,
			$size: "small",
			$final-opts: (line-height: 1, letter-spacing: normal),
			$fallback-opts: (letter-spacing: -0.2px)
		);

		line-height: inherit;
	}

	.icon-featured {
		color: $color-shade-light;
		margin-right: auto;
		margin-bottom: 0.5rem;
		margin-left: auto;
	}

	.column > & {
		//		height: 100%;
	}
}

.link-block {
	figure {
		margin-top: 0;
		margin-bottom: 0;

		> img {
			margin-bottom: rem-calc(22);
		}
	}

	.match-height & {
		display: flex;
	}
}

.link-block,
.quote--circle-portrait {
	display: block;
	text-align: center;
	color: $color-text;

	.heading {
		color: $color-primary;
	}
}

.quote--circle-portrait {
	.image-circle-wrapper {
		width: 100%;
		max-width: rem-calc(200);
		margin-right: auto;
		margin-left: auto;
	}

	.image--circle {
		margin-right: auto;
		margin-bottom: rem-calc(30);
		margin-left: auto;
		overflow: hidden;
	}

	&.quote--image-small {
		.image-circle-wrapper {
			max-width: 6.5rem;
		}

		.image--circle {
			margin-bottom: 0.875rem;
		}
	}
}

.statistic-item {
	text-align: center;
	position: relative;

	.icon-wrapper {
		margin-right: auto;
		margin-left: auto;
		background-color: $color-senary;
	}

	.statistic {
		color: $color-senary;
	}

	.statistic__value,
	.statistic__units {
		@include font-family($font-secondary);
		@include font-size(h1);
	}

	.statistic__value {
		font-weight: bold;
	}

	.statistic__units {
		font-weight: lighter;
	}

	.statistic__subtitle {
		@include font-size("small");

		font-weight: bold;
		text-transform: uppercase;
	}

	p:last-child {
		margin-bottom: 0;
	}
}

.text-with-button {
	align-items: flex-start;
}

.text-circle {
	@include colored-bg(rgba($color-primary, 0.8));

	display: flex;
	flex-flow: column nowrap;

	justify-content: center;

	border-radius: 50%;

	padding: 10%;

	> * {
		position: relative;
	}
}

.info-block {
	.hgroup {
		.superheader {
			@include heading-gamma;
			&:not([class*="text-"]) {
				color: $color-text;
			}
			margin-bottom: 0.5rem;
		}

		h2 {
			@include heading-alpha;
		}
	}

	.info-block__links {
		@include strip-list;
		li {
			margin-bottom: 1rem;
			font-weight: bold;

			> a {
				@include inline-icon;
				@include anchor-colors($color-primary, $color-secondary);
				@include heading-epsilon;
				display: block;

				margin-bottom: 0.5rem;
			}
		}
	}
}

figure.caption-overlay.info-block__photo {
	> a {
		display: block;
	}

	figcaption {
		@include heading-gamma;
		//background-color: $color-quinary;
		background-color: $black;

		position: relative;
		padding: 0.5rem 1rem;

		@include breakpoint(large) {
			position: absolute;
			padding: 1rem 1.5rem;
			background-color: rgba($color-quinary, 0.8);
		}
	}
}

figure.info-block__photo.news-caption {
	> a {
		display: block;
	}

	figcaption {
		color: $color-primary;
		font-size: 1.25rem !important;
		font-family: $font-secondary;
		font-weight: normal;
		line-height: 1.25 !important;

		position: relative;
		padding: 0.5rem 0;

		&:hover, &:focus {
			color: $color-teal;
		}

		span.date {
			color: $color-teal;
			font-size: 1.15rem !important;
			line-height: 1.75 !important;
			&:hover, &:focus {
				color: $color-primary;
			}
		}

		&.HoC {
			font-size: 1.4rem !important;
			line-height: 1.1 !important;
			padding-top: 0.6rem;
			span {
				color: $black;
				font-size: 1rem !important;
				text-transform: uppercase;
				&:hover, &:focus {
					color: $color-teal;
				}
			}
		}

		&.more-stories {
			font-size: rem-calc(22) !important;
			line-height: 1.1 !important;
			padding-top: 0.6rem;
			span {
				display: block;
				margin-top: rem-calc(9) !important;
				font-size: 1rem !important;
			}
		}
	}
}

figure.info-block__photo.caption-overlay--black {
	> a {
		display: block;
	}

	figcaption {
		font-size: 1.6rem !important;
		font-family: $font-secondary;
		font-weight: normal;
		line-height: 1.1 !important;
		background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
		margin-top: 0 !important;
		position: absolute;
		top: 0;
		height: 100%;
		display: flex;
		align-items: flex-end;
		padding: 0 1rem;
		padding-bottom: 1.5rem;

		&:hover, &:focus {
			color: $color-teal;
		}

		@include breakpoint(medium) {
			font-size: 1.2rem !important;
			padding-bottom: 1rem;
		}
		@include breakpoint(large) {
			font-size: 1.6rem !important;
			padding: 0 1.5rem;
			padding-bottom: 1.5rem;
		}
	}

	// Hacky way to turn off the black gradient overlay
	&.caption-overlay--none {
		figcaption {
			background: transparent;
			// Put a dropshadow effect on text
			text-shadow: 3px 3px 6px #000000;
		}
	}
}

.content-wrapper > section.has-bg.discover-clarke {
	padding-bottom: 1rem;

	@include breakpoint(420px) {
		padding-bottom: 5.25rem;
	}
}

.discover-blocks {
	> .discover__block {
		position: relative;
		z-index: 1;
		background-color: $white;

		margin-bottom: 2.125rem;

		.image-background {
			background-color: rgba($color-primary, 0.8);
			height: 10rem;
			overflow: hidden;

			margin-bottom: 1rem;

			img {
				position: relative;
				top: -25%;
			}
		}

		.discover__heading,
		.discover__content {
			padding-right: 1rem;
			padding-left: 1rem;

			.button {
				display: block;
				margin-right: auto;
				margin-left: auto;
			}
		}

		.discover__heading {
			width: 100%;
			color: $color-primary;
			text-align: center;
			margin-bottom: 1rem;

			> div {
				width: 100%;
			}

			.icon-wrapper {
				color: inherit;
				margin-right: auto;
				margin-bottom: 0;
				margin-left: auto;

				svg {
					fill: $color-primary;
				}
			}
		}

		.discover__content {
			@include anchor-colors($color-text);
			display: block;
		}
	}

	@media screen and (min-width: 30rem) and (max-width: 39.9375rem) {
		> .discover__block {
			img {
				//scss-lint:disable ImportantRule
				top: 3.4375rem;
			}
		}
	}
	//scss-lint:disable DeclarationOrder VendorPrefix
	@include breakpoint(480px) {
		display: flex;
		// display: -webkit-flex;
		position: relative;
		flex-flow: row wrap;
		// -webkit-flex-wrap: wrap;
		// -webkit-flex-direction: row;

		max-width: 100%;

		> .discover__block {
			display: flex;
			// display: -webkit-flex;
			flex-flow: column nowrap;
			// -webkit-flex-wrap: nowrap;
			// -webkit-flex-direction: column;

			background-color: $color-primary;

			width: 50%;
			// min-width: 50%;
			max-width: 50%;
			height: auto;
			min-height: rem-calc(350);
			// min-height: 0;
			flex: 1 0 auto;

			cursor: pointer;

			margin-top: 0.5rem;
			margin-bottom: 0.5rem;

			box-shadow: 0 0 0 rgba($black, 0);

			transition: top $transition-time ease, width $transition-time ease, height $transition-time ease,
				margin $transition-time ease, box-shadow $transition-time ease;

			.image-background {
				top: 0;
				@include pseudo-cover($beneath: true, $fill: "both", $pin: "center" "top");
				@include color-overlay($color: shade($color-primary, 0.2), $opacity: 0.5, $mode: screen);
				overflow: hidden;
				width: 100.1%;
				height: 100%;

				img {
					//scss-lint:disable ImportantRule
					opacity: 0.6 !important;
				}
			}

			.discover__content {
				@include font-size("large");
				opacity: 0;
				height: 0;

				color: $white;
				@include anchor-colors($white);

				background-color: $color-tertiary;
				background-image: url("images/watercolor.png");

				text-align: center;
			}

			.discover__heading {
				position: relative;
				display: flex;
				display: -webkit-flex;
				height: rem-calc(135);

				margin-bottom: 0;

				background-color: $white;

				justify-content: center;
				align-items: center;

				z-index: 2;

				padding: 2rem;

				transition: top $transition-time ease, transform $transition-time ease, padding $transition-time ease;

				.heading--gamma {
					margin-bottom: -2rem;

					transition: margin $transition-time ease;
				}

				.icon-wrapper {
					position: relative;

					opacity: 0;
					margin: auto;
					transition: opacity $transition-time / 2 ease-in;
				}
			}

			&:hover,
			&:focus,
			&:active {
				overflow: visible;

				z-index: 2;

				box-shadow: 0 0 20px rgba($black, 0.5);

				.discover__content {
					display: flex;
					display: -webkit-flex;
					position: relative;
					flex-flow: column nowrap;
					-webkit-flex-direction: column;
					-webkit-flex-wrap: nowrap;
					justify-content: center;
					align-items: center;

					height: auto;

					padding: 1rem;
					flex: 1 1 auto;
					opacity: 1;
				}

				.discover__heading {
					top: 0;
					height: auto;
					padding: 1rem 2rem;

					.heading--gamma {
						margin-bottom: 0;
					}

					.icon-wrapper {
						opacity: 1;
						transition: opacity $transition-time ease-out;
					}
				}
			}
		}
	}

	@include breakpoint(640px) {
		> .discover__block {
			height: rem-calc(600);

			.discover__heading {
				top: 50%;
				transform: translateY(-50%);
			}

			@include hover {
				.discover__heading {
					top: 0;
					transform: none;
				}
			}

			.image-background img {
				top: 0;
			}
		}
	}

	@include breakpoint(960px) {
		flex-wrap: nowrap;

		> .discover__block {
			min-height: rem-calc(600);
			width: 25%;

			&:hover,
			&:focus {
				.discover__heading {
					.icon-wrapper {
						opacity: 1;
					}
				}
			}
		}
	}
}

.discover__block {
	> * {
		position: relative;
	}

	.image-background {
		.mix-blend-mode & {
			> img {
				opacity: 1;
			}
		}
	}
}

/* for the newsroom template */
.content-wrapper > section.in-the-news, .content-wrapper > section#humans-of-clarke, .content-wrapper > section#newsroom-contact {

	.column {
		margin-bottom: 1.1rem !important;
		padding-left: 0.6rem !important;
		padding-right: 0.6rem !important;
	}

	.button {
		margin-right: 0 !important
	}

	div.column--center,div.column.column--center {
		text-align: center;
	}
}

.social-stream--newsroom {
	.column {
		//background-color: #ccc;
		background-color: #fff;
		padding-top: rem-calc(20);
		@include breakpoint(medium) {
			padding-top: rem-calc(40);
		}
	}
}

section.in-the-news {
	margin-top: 1rem;
	margin-bottom: 0 !important;
}

section#humans-of-clarke {
	padding-top: rem-calc(38) !important;
	padding-bottom: rem-calc(30) !important;
	margin-bottom: rem-calc(50) !important;

	.info-block__photo {
		@include breakpoint(medium) {
			margin-bottom: rem-calc(20);
		}
	}

	.HoC_logo {
		@include breakpoint(medium) {
			display: flex;
			align-items: center;
		}
	}
	.HoC_divider {
		@include breakpoint(medium) {
			background-image: url(/wp-content/themes/clarke-timber/images/divider.svg);
			background-repeat: no-repeat;
			background-position-y: center;
		}
	}
	p {
		@include breakpoint(medium) {
			margin: rem-calc(0 0 15 30);
		}
	}
}

section#newsroom-contact {
	.hgroup {
		margin-bottom: 1rem;
	}
	h2 {
		font-size: rem-calc(34);
		text-transform: none;
	}
	p {
		font-size: larger;
		span {
			display: block;
			font-weight: bold;
		}
		a {
			color: $color-primary;
			&:hover, &:focus {
				color: $color-teal;
			}
		}
	}
}

.news-hero-image {
	margin-bottom: 2.5rem;
}

// Adjust padding only for asides in news posts
.single-post .aside.column.medium-12 {
	padding-left: 0;
	padding-right: 0;
	@include breakpoint(medium) {
		padding-left: 1.3125rem;
	}
}
