.border {
	&-top {
		border-top: $border-med;
	}

	&-right {
		border-right: $border-med;
	}

	&-bottom {
		border-bottom: $border-med;
	}

	&-left {
		border-left: $border-med;
	}

}
