// Background Classes


@each $color-name, $color-value in $colors-normal {
	.bg-#{$color-name} {
		@include colored-bg($color-value);
	}
}

@each $color-name, $color-value in $colors-accents {
	.bg-accent-#{$color-name} {
		@include colored-bg($color-value);
	}
}

@each $color-name, $color-value in $colors-shades {
	@if ($color-name == 'shade') {
		.bg-shade {
			@include colored-bg($color-value);
		}
	} @else {
		.bg-shade-#{$color-name} {
			@include colored-bg($color-value);
		}
	}
}

.bg--photo-color {
	@include dark-bg-image($opacity: 0.4);
}

.bg-secondary {

	a:not(.button) {
		@include anchor-colors($white, $color-tertiary);
		font-weight: bold;
	}

}
