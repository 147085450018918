figure {
	margin-right: 0;
	margin-left: 0;
	margin-top: 0;
	margin-bottom: 0;

	max-width: $global-width;

	.page--narrow & {
		max-width: rem-calc(860);
	}

	> figcaption {
		margin-top: 0.875rem;

		color: $color-shade-dark;

		.Muli-loaded & {
			margin-top: 0.5rem;
		}
	}

	img {
		display: block;
		width: 100%;
		max-width: 100%;
		}

	cite {
		display: block;
	}

	&.caption-overlay {
		position: relative;

		img {
			margin-bottom: 0;
			width: 100%;
			max-width: 100%;
		}

		figcaption {
			width: 100%;
			color: $white;
			background-color: $black;

			padding: 0.5em;

			@include breakpoint(medium) {
				position: absolute;
				padding: 1em;
				left: 0;
				right: 0;
				bottom: 0;

				background-color: rgba(0, 0, 0, 0.7);
			}
		}
	}


}


figcaption {
	p:last-child,
	> *:last-child p:last-child {
		margin-bottom: 0;
	}
}

.image--featured {
	margin-bottom: 1rem;

	@include breakpoint(medium) {
		width: 50%;
		float: right;
		margin-left: map-get($grid-column-gutter, small) / 2;
		padding-left: map-get($grid-column-gutter, small) / 2;
	}
}

.video-preview-link {
	position: relative;
	display: block;
}

.video-preview__overlay {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	color: $white;

	background-color: rgba($black, 0);

	transition: background-color $transition-time ease-out;

	@include hover {
		background-color: rgba($black, 0.6);

		.icon-wrapper {
			right: 50%;
			bottom: 50%;

			transform: scale(1) translate3d(50%, 50%, 0);

		}
	}

	.icon-wrapper {
		position: absolute;
		right: 0;
		bottom: 0;

		background-color: rgba($black, 0.6);

		border-radius: 50%;
		width: 8em;
		height: 8em;

		transform: scale(0.5) translate3d(0, 0, 0);

		transition: right $transition-time ease-out,
			bottom $transition-time ease-out,
			transform $transition-time ease-out;

		svg {
			width: 60%;
			height: 60%;

			margin-left: calc((100% - 60%) / 2 + 0.5em);
			margin-top: calc((100% - 50%) / 2);
		}
	}
}

.video-preview {
	position: relative;

	img {
		width: 100%;
	}

	.video-preview__overlay {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		color: $white;

		background-color: rgba($black, 0);

		transition: background-color $transition-time ease-out;

		@include hover {
			background-color: rgba($black, 0.6);

			.icon-wrapper {
				right: 50%;
				bottom: 50%;

				transform: scale(1) translate3d(50%, 50%, 0);

			}
		}

		.icon-wrapper {
			position: absolute;
			right: 0;
			bottom: 0;

			background-color: rgba($black, 0.6);

			border-radius: 50%;
			width: 8em;
			height: 8em;

			transform: scale(0.5) translate3d(0, 0, 0);

			transition: right $transition-time ease-out,
				bottom $transition-time ease-out,
				transform $transition-time ease-out;

			svg {
				width: 60%;
				height: 60%;

				margin-left: calc((100% - 60%) / 2 + 0.5em);
				margin-top: calc((100% - 50%) / 2);
			}
		}
	}
}

.reveal.photo-lightbox {
	width: calc(100vw - 2rem);
	max-width: rem-calc(1200);

	height: auto;
	min-height: 0;

	margin: auto;
	padding-top: 2.75rem;

	.lightbox-caption {
		@include font-size('large');
		margin-top: 1rem;

	}

	//scss-lint:disable DeclarationOrder
	@include breakpoint(small only) {
		width: 100%;
		padding-right: 0;
		padding-left: 0;

		.lightbox-caption {
			padding: 0 1rem;
		}
	}
}

// Additional styles to allow Wordpress's inline media styles work
.alignright {
	float: right;
	@include breakpoint(medium) {
		margin-left: rem-calc(20);
	}
}

.alignleft {
	float: left;
	@include breakpoint(medium) {
		margin-right: rem-calc(20);
	}
}
