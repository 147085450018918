.bg-secondary {
	color: $white;
}

.modal--video {
	width: 100%;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: $black;

	.video-wrapper {
		max-width: calc((70vh - 6rem) * 16 / 9);
		margin-right: auto;
		margin-left: auto;
	}

	button {
		color: $white;

		@include hover {
			color: $color-shade-light;
		}
	}
}

@include breakpoint (medium down) {
	.modal--video {
		//scss-lint:disable ImportantRule
		height: auto;
		min-height: 0;
		top: 50vh !important;

		transform: translateY(-50%);
	}
}

.accordion {
	background-color: transparent;

	> li {
		margin-left: 0;
		margin-bottom: 1rem;

		text-indent: 0;
		background-color: $accordion-background;

		.accordion-title {
			@include font-size( 'large');
			font-weight: bold;
			border-left: $border-thick;
			border-right: $border-thick;
			border-color: transparent;

			display: flex;
			flex-flow: row nowrap;
			align-items: center;

			@include hover {
				color: $accordion-item-color;
				border-color: rgba($white, 0.5);
			}

			.icon-wrapper {
				position: relative;
				width: 1.25em;
				height: 1.25em;
				margin-right: 0.75em;

				svg {
					transform: rotateZ(0);
					transition: transform $transition-time ease-out;
				}
			}

			&:active,
			&:focus {
				background-color: transparent;
			}

		}

		&.is-active {
			background-color: $color-primary;

			.accordion-title {
				&:hover {
					background-color: lighten($color-primary, 10%);
				}

				.icon-wrapper {
					svg {
						transform: rotateZ(90deg);
					}
				}

			}

		}

		p:last-child {
			margin-bottom: 0;
		}

		&::before {
			display: none;
		}
	}
}

// Calendar iFrame
iframe.calendar-frame {
	border: 0;
	margin-bottom: 2.125rem;
}

.calendar-links {
	display: flex;
	justify-content: flex-end;

	> * {
		display: block;
		margin: 0 0 0 1rem;
	}
}

//TODO: Organize these components to match Patternlab

// foundation has some wonky markup for this
//.accordion {
//  &:not(:last-child) {
//    margin-bottom: 1rem;
//  }
//  .accordion-navigation {
//    > a {
//      // the title tab
//      color: $gray;
//      background-color: $light-gray;
//      padding: .5rem 1rem;
//      margin-bottom: rem-calc(4);
//    }
//    &.active {
//      > a {
//        background: $color-light-background;
//        color: $color-brand;
//      }
//    }
//  }
//
//  &.chevrons {
//    .accordion-navigation {
//      > a {
//        &:before {
//          @extend .icon-prepend;
//          content: $fa-var-chevron-right;
//        }
//      }
//      &.active {
//        > a {
//          &:before {
//            content: $fa-var-chevron-down;
//          }
//        }
//      }
//    }
//  }
//
//  &.split {
//    .accordion-navigation {
//      border-top: 1px solid $color-brand;
//      padding-top: rem-calc(6);
//      .columns {
//        padding: 1rem 2rem;
//      }
//      > a {
//        padding: 0;
//        margin-bottom: rem-calc(6);
//      }
//      .uppercase {
//        font-size: rem-calc(22);
//        line-height: 3rem;
//      }
//      .button {
//        margin-left: 1rem;
//      }
//    }
//  }
//}
//
//.callout-carousel {
//	margin-top: 2rem;
//	$slide-padding: 4%;
//	.slide {
//		position: relative;
//		figure {
//			transition: all 300ms ease;
//			img {
//				width: 100%;
//				height: auto;
//			}
//			@include breakpoint(large) {
//				padding: $slide-padding;
//			}
//		}
//		figcaption {
//			transition: opacity 900ms ease;
//		}
//		&.slick-current {
//			figure {
//				@include breakpoint(large) {
//					transform: scale(1.15);
//				}
//			}
//		}
//		&:not(.slick-current) {
//			opacity: .5;
//			figcaption {
//				opacity: 0;
//			}
//			.callout-carousel-next {
//				display: none !important;
//			}
//		}
//	}
//
//	.callout-carousel-next {
//		background-color: $black;
//		height: rem-calc(80);
//		@extend .fa;
//		font-size: 0;
//		padding: 0 .5rem;
//		&:before {
//			content: $fa-var-chevron-right;
//			font-size: 1rem;
//		}
//		position: absolute;
//		right: 0;
//		bottom: 0;
//		transform: translateX(100%);
//		z-index: 99;
//		@include breakpoint(large) {
//			right: -$slide-padding;
//			transform: translate(102%, -50%);
//			top: 50%;
//		}
//	}
//}
//
//.slick-prev {
//    left: -($slick-button-distance);
//    [dir="rtl"] & {
//        left: auto;
//        right: -($slick-button-distance);
//    }
//}
//
//.slick-next {
//    right: -($slick-button-distance);
//    [dir="rtl"] & {
//        left: -($slick-button-distance);
//        right: auto;
//    }
//}
//
//.slick-dots {
//	li::before {
//		content: '';
//		font-size: 0;
//		line-height: 0;
//	}
//}
//
//.callout {
//  margin-bottom: 2rem;
//
//	&.bg-dark {
//		background-color: $color-primary;
//	}
//
//  a, h2, h3, h4, h5, h6 {
////    font-family: $font-secondary;
////    text-transform: uppercase;
//  }
//
//  h3 {
//    color: white;
//    padding-bottom: 3rem;
//    font-size: rem-calc(26);
//  }
//  h5 {
//    color: $color-brand;
//  }
//
//  a {
//    display: inline-block;
////    border-top: 1px solid $color-brand;
////    padding-top: 1em;
////    margin-top: .5rem;
//    &:hover {
////      color: $white;
//    }
//  }
//
//  p {
//    font-size: rem-calc(14);
//  }
//
//  .callout-outer {
//    background: transparent;
//    padding: 1.3rem;
//  }
//
//  &.bg-light {
//    h3, p {
//      color: $black;
//    }
//  }
//
//  &.bg-brand, &.bg-complement {
//    h5 {
//      color: $white;
//    }
//    a {
//      color: $white;
//      border-color: $white;
//    }
//  }
//
//  &.bg-alpha {
//    position: relative;
//    .callout-inner {
//      opacity: 1;
//      position: relative;
//      z-index: 2;
//    }
//  }
//}
//
//.image-callout {
//  background-color: $color-brand;
//  img {
//    width: 100%;
//  }
//  h5 {
//    max-width: 66%;
//    color: white;
//  }
//  p {
//    font-size: rem-calc(14);
//    padding: 0 rem-calc(30);
//  }
//  figcaption {
//    a {
//      display: block;
//      text-align: right;
//      height: rem-calc(25);
//      overflow: hidden;
//      &:before {
//        position: absolute;
//        z-index: 2;
//        color: $white;
//        right: rem-calc(10);
//        top: rem-calc(5);
//      }
//      &:after {
//        z-index: 1;
//        content: '';
//        position: absolute;
//        left: 0;
//        right: 0;
//        height: 200%;
//        width: 200%;
//        background-color: $color-dark-overlay;
//        transform: rotate(-1deg);
//      }
//    }
//    p {
//      color: $white;
//    }
//  }
//}
//
//.topline-callout {
//  margin-bottom: 1rem;
//  padding: 0 1.5rem 2rem 1.5rem;
//  border-width: 6px 0 0 0;
//  border-style: solid;
//  border-color: $color-brand;
//  &.bg-light {
//    border-color: $color-light-border;
//  }
//  .section-heading {
//    margin-bottom: 1rem;
//  }
//}
//
//.icon-callout {
//  margin: 2rem auto;
//  text-align: center;
//  display: block;
//  background-image: image-url('icon-letter.png');
//  background-position: 50% 0;
//  background-repeat: no-repeat;
//  padding-top: rem-calc(65) + .5rem;
//  * {
//    color: $gray;
//  }
//  h5 {
//    font-size: rem-calc(12);
//  }
//  h2 {
//    font-size: rem-calc(20);
//    &::before, &::after {
//      border-color: $color-brand;
//    }
//  }
//}
//
//$tabspacing: .7rem;
//
//// the tab titles/switcher
//.tabs {
//  .tab-title {
//    position: relative;
//  }
//  &.expand {
//    &:before, &:after {
//      display: none;
//    }
//    display: table;
//    width: 100%;
//    .tab-title {
//      display: table-cell;
//      float: none;
//    }
//  }
//  &.block-tabs {
//    .tab-title {
//      &:not(:last-child) {
//        margin-right: $tabspacing;
//      }
//      &:after {
//        transition: all $transition-time;
//        position: absolute;
//        content: '';
//        display: block;
//        left: 50%;
//        bottom: -.3rem;
//        height: 1rem;
//        width: 1rem;
//        transform: translateX(-50%) rotate(45deg);
//        background-color: $color-brand-darken;
//        opacity: 0;
//      }
//      a {
//        color: $white;
//        background-color: $color-brand-darken;
//        @extend .loud;
//        text-align: center;
//        font-weight: 300;
//        font-size: 1rem;
//      }
//      &.active, &:hover, {
//        a {
//          color: white;
//          background-color: $color-brand;
//        }
//        // active tab decoration;
//        &:after {
//          background-color: $color-brand;
//          opacity: 1;
//        }
//      }
//    }
//    &.expand {
//      .tab-title {
//        &:not(:first-child) {
//          a {
//            margin-left: $tabspacing / 2;
//          }
//        }
//        &:not(:last-child) {
//          a {
//            margin-right: $tabspacing / 2;
//          }
//        }
//      }
//    }
//  }
//  &.page-tabs {
//    .tab-title {
//      position: relative;
//      @include breakpoint(small only) {
//        display: block;
//        background-color: $color-brand-darken;
//      }
//      &:before {
//        @include breakpoint(medium) {
//          content: '';
//          display: block;
//          background-color: rgba($color-brand-darken, .5);
//          top: rem-calc(7);
//          left: $negative-bleed;
//          right: $negative-bleed;
//          z-index: 1;
//        }
//      }
//      > a {
//        position: relative;
//        z-index: 2;
//        padding: 1rem;
//        background-color: transparent;
//        color: $white;
//        text-align: center;
//        * {
//          color: inherit;
//        }
//        dl {
//          display: inline-block;
//          width: 100%;
//        }
//        dt {
//          @extend .loud;
//          font-size: rem-calc(22);
//        }
//        dd {
//          font-size: rem-calc(12);
//          font-style: italic;
//          float: none;
//        }
//      }
//      &.active {
//        > a {
//          background-color: $white;
//          background-image: image-url('tab-shadow.png');
//          background-repeat: no-repeat;
//          background-size: cover;
//          @include breakpoint(medium) {
//            background-size: contain;
//          }
//          background-position: 50% 100%;
//          color: $color-brand;
//        }
//      }
//    }
//  }
//}
//
//// tab content
//.tabs-content {
//  &.block-tabs {
//    padding-top: $tabspacing;
//    .content {
//      background-color: $color-brand;
//      color: $white;
//      padding: 1rem 1.5rem 2rem 1.5rem;
//    }
//    .bracketed {
//      width: 100%;
//      padding: 1rem 2rem 1rem 3rem;
//    }
//  }
//  &.page-tabs {
//    .content {
//      padding: 2rem 0;
//    }
//  }
//}
//
//// layout on academics landing page
//section.page-tabs {
//  padding-bottom: 1rem;
//  @include breakpoint(large) {
//    margin-top: rem-calc(-115);
//    background-image: image-url('glint.png');
//    background-position: 50% rem-calc(5);
//    background-size: 100% auto;
//    background-repeat: no-repeat;
//  }
//}
//
//// Links to social media accounts
//
//.social-links {
//
//	a {
//		display: inline-block;
//		font-size: 1em;
//		letter-spacing: -1em;
//		color: transparent;
//
//		&::before {
//			display: inline-block;
//			font-family: $icon-font;
//			color: $color-tertiary;
//			font-size: 2em;
//			width: 1em;
//			height: 1em;
//			line-height: 1;
//		}
//
//		&.social-facebook::before {
//			content: $icon-facebook;
//			color: $color-facebook;
//		}
//
//		&.social-twitter::before {
//			content: $icon-twitter;
//			color: $color-twitter;
//		}
//
//		&.social-linkedin::before {
//			content: $icon-linkedin;
//			color: $color-linkedin;
//		}
//
//		&.social-google::before {
//			content: $icon-google-plus;
//			color: $color-google-plus;
//		}
//
//	}
//
//}
//
//.tooltip-container {
//	display: inline-block;
//	position: relative;
//
//	&:hover {
//		.tooltip {
//			display: block;
//		}
//	}
//}
//
//.tooltip-link {
//
//}
//
//.tooltip {
//	display: none;
//	position: absolute;
//	top: 1.5em;
//	left: 0;
//	width: 18em;
//	padding: $pad;
//	background: $white;
//	border: 1px solid $gray;
//	box-shadow: 0.3em 0.3em 1em 0 rgba(0,0,0,0.2);
//
//	h2 {
//		margin-top: 0;
//	}
//
//}
