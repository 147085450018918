$color-alert--low: #8dc6cc;
$color-alert--medium: #aece5c;
$color-alert--high: #f1d52e;
$color-alert--max: #cf0500;

.alert--in-page,
.alert--header {
	text-align: center;

	background-image: url("images/diagonal-stripe.svg");

	background-position: top center;

	background-repeat: repeat-x;

	background-size: 140px auto;

	overflow: hidden;

	max-height: 1200px;

	.content-wrapper > &:first-child {
		margin-top: -2.125rem;
		margin-bottom: 2.125rem;
	}

	.alert-hidden {
		max-height: 0;
	}

	.alert__contents {
		position: relative;
		padding: 3.5rem 1.5rem 2.5rem;
	}

	.close-button {
		@include font-size("small");
		text-transform: uppercase;

		color: $color-primary;

		@include hover {
			color: lighten($color-primary, 5%);
		}

		.icon-wrapper {
			font-size: 0.5em;
			vertical-align: -2px;
		}
	}

	.hgroup,
	.hgroup .icon-wrapper {
		margin-bottom: 1.5rem;
	}

	.icon-wrapper {
		margin-right: auto;
		margin-left: auto;
	}

	.alert__title {
		@include heading-gamma;
	}

	.alert__text {
		max-width: calc(#{rem-calc(860)} + #{map-get($grid-column-gutter, small)});

		margin-right: auto;
		margin-left: auto;
	}

	a {
		@include anchor-colors($color-primary, lighten($color-primary, 5%));

		.alert__title {
			@include anchor-underline("all", "border", $border-med, 0, currentColor);
			display: inline-block;
			padding-bottom: 0;
		}
	}
}

.alert--in-page {
	margin-bottom: 2.125rem;
}

.alert--low,
.alert--1 {
	background-color: $color-alert--low;
}

.alert--medium,
.alert--2 {
	background-color: $color-alert--medium;
}

.alert--high,
.alert--3 {
	background-color: $color-alert--high;
}

.alert--max,
.alert--4 {
	background-color: $color-alert--max;
	color: $white;

	a,
	.close-button {
		@include anchor-colors($white);
		color: inherit;
	}
}

.notfound-error {
	@include font-size("large");
	padding-top: rem-calc(60);
	padding-bottom: rem-calc(100);
	max-width: rem-calc(840);

	> * {
		text-align: center;
	}

	.error-icon {
		margin-bottom: rem-calc(50);
	}

	h1,
	.error-message {
		margin-bottom: rem-calc(38);
	}
}

.error-message {
	@include heading-beta;
	text-transform: none;
}

.error-icon {
	@include colored-bg($color-quinary);
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;

	color: $white;

	margin: auto;

	width: rem-calc(72);
	height: rem-calc(72);

	.icon-wrapper {
		width: 2.5rem;
		height: 2.5rem;
	}
}
