@mixin font-size($size, $breakpoint: "medium", $scale: 1, $lh: null) {
	$match: false;
	@each $name, $value in map-get($header-sizes, "medium") {
		@if ($match != true) {
			@if ($size == $name) {
				@if ($lh != null) {
					@include heading-font-size($size, $scale: $scale, $lh: $lh);
				} @else {
					@include heading-font-size($size, $scale: $scale);
				}
				$match: true;
			}
		}
	}
	@if ($match != true) {
		font-size: rem-calc(map-deep-get($font-sizes, $breakpoint, $size)) * $scale;
	}
}

@mixin font-family($font, $fallback: $font-stack-sans) {
	font-family: $fallback;

	//	.fonts-loaded &,
	.#{str-replace(#{nth($font, 1)}, " ", "-" )}-loaded & {
		font-family: $font;
	}
}

@mixin font($font, $fallback: $font-stack-sans, $size: "medium", $scale: 1, $final-opts: (), $fallback-opts: ()) {
	font-family: $fallback;
	@each $attr, $value in $fallback-opts {
		#{$attr}: #{$value};
	}

	@include font-size($size, $scale: 1);

	.#{str-replace(#{nth($font, 1)}, " ", "-" )}-loaded &:not([class*="text-"]) {
		@if (map-get($final-opts, line-height)) {
			@include font-size($size, $scale: $scale, $lh: map-get($final-opts, line-height));
		} @else {
			@include font-size($size, $scale: $scale);
		}

		font-family: $font;
		@each $attr, $value in $final-opts {
			#{$attr}: #{$value};
		}
	}
}

@function heading-size($heading-level, $breakpoint: "medium", $lh: $header-lineheight) {
	@return rem-calc(map-deep-get($header-sizes, $breakpoint, $heading-level));
}

@mixin heading-font-size($heading-level, $scale: 1, $lh: $header-lineheight) {
	font-size: heading-size($heading-level, "small") * $scale;

	@include breakpoint(medium) {
		font-size: heading-size($heading-level) * $scale;
	}

	@include breakpoint(large) {
		font-size: heading-size($heading-level, "large") * $scale;
	}

	margin-top: -1em * ($lh - 1);
}
