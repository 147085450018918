/*------------------------------------*\
    $IMAGE ELEMENTS
\*------------------------------------*/

.image--circle {
	@include image-circle;
}

.has-photo-bg {
	position: relative;
	overflow: hidden;

	> * {
		position: relative;
		z-index: 5;
	}

	.photo-bg {
		@include pseudo-cover(true);
		margin: 0;

		&.photo-bg--primary {
			@include color-overlay($color-primary);
		}

		&.photo-bg--secondary {
			@include color-overlay($color-secondary, $opacity: 0.5);
		}

		&.photo-bg--tertiary {
			@include color-overlay($color-tertiary);
		}

		&.photo-bg--quaternary {
			@include color-overlay($color: $color-quaternary, $mode: screen, $opacity: 1);
		}

		&.photo-bg--primary,
		&.photo-bg--secondary,
		&.photo-bg--quaternary,
		&.photo-bg--tertiary {
			> img {
				filter: blur(2px);
			}
		}

		&.photo-bg--light {
			opacity: 0.25;
		}
		&.photo-bg--lighter {
			opacity: 0.1;
		}
	}
}

hr {
	margin-top: 0;
	margin-bottom: rem-calc(27);

	.page--narrow & {
		max-width: calc(#{rem-calc(860)} + #{map-get($grid-column-gutter, small)});
	}

	&.triangles,
	&.dots {
		border: 0;
		height: 0.5rem;
	}

	&.dots {
		//scss-lint:disable DuplicateProperty
		background-image: url('images/sketchy-dots-gray.svg');
		background-repeat: repeat-x;
		background-size: 54px 9px;

		&.dots--light {
			background-image: url('images/sketchy-dots-gray-light.svg');
		}

		&.dots--white {
			background-image: url('images/sketchy-dots-white.svg');
		}

		&.dots-narrow {
			height: 0.75rem;
			background-image: url('images/sketchy-dots-3-gray.svg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: auto 100%;
		}
	}

	&.triangles {
		//scss-lint:disable DuplicateProperty
		background-image: url('images/sketchy-triangles-tertiary.svg');
		background-repeat: repeat-x;
		background-size: 54px 9px;
	}
}

.image-wrapper-3x2 {
	position: relative;
	height: 0;
	padding-bottom: percentage(2 / 3);
	overflow: hidden;
	background-color: rgba($color-primary, 0.25);

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
	}
}
